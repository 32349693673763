import React, { useState, useEffect } from 'react';
import CustomButton from '../../shared-components/Button/custom-button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "react-bootstrap";
import { Calendar } from 'primereact/calendar';
import { Form, Dropdown, Card, Badge } from "react-bootstrap";
import './design.css';

const DisplayLogicQuestion = (props) => {
    const { newList, operators, question, onChange, saveDLForEachQuestion } = props;
    const [conditions, setConditions] = useState([]);
    const [showdLText, setShowdLText] = useState('');
    const [showVisibleIfText, setShowVisibleIfText] = useState('');
    const [anyChange, setAnyChange] = useState(false);
    const logicalOperators = [
        { text: 'And', value: 'and' },
        { text: 'Or', value: 'or' },
    ];
    const [selectedUpdatedQuestion, setSelectedUpdatedQuestion] = useState(null);

    useEffect(() => {
        const parseDLFormulaText = (DLFormulaText) => {
            const conditions = [];

            const regex = /(?:\((.*?)\)|{([^}]*)})\s*([><=!]+)\s*((?:\[.*?\]|[\w'-]+))(?:\s+(and|or)\s*)?/g;

            let match;
            while ((match = regex.exec(DLFormulaText)) !== null) {
                const tempId = match[2] || match[1];
                const operator = match[3];
                let option = match[4];
                const logicalOperator = match[5] !== undefined ? ` ${match[5]} ` : '';

                if (option.startsWith('[') && option.endsWith(']')) {
                    option = option.slice(1, -1).split(',').map(item => item.trim());
                }

                const selectedQuestion = newList.find((item) => item.tempId === tempId);
                conditions.push({
                    selectedTempID: tempId,
                    selectedOperator: operator,
                    selectedOption: option,
                    selectedLogicalOperator: logicalOperator.trim(),
                    selectedQuestion: selectedQuestion,
                });
            }

            setConditions(conditions);
        };

        if (question.DLFormulaText) {
            parseDLFormulaText(question.DLFormulaText);
        }
    }, [question.DLFormulaText, newList]);

    useEffect(() => {
        const updateDLFormulaText = () => {
            const DLFormulaText = conditions
                .map((condition, index) => {
                    const {
                        selectedTempID,
                        selectedOperator,
                        selectedOption,
                        selectedLogicalOperator,
                    } = condition;

                    if (selectedTempID && selectedOperator) {
                        const logicalOperator = selectedLogicalOperator ? ` ${selectedLogicalOperator} ` : '';

                        if (Array.isArray(selectedOption)) {
                            return `{${selectedTempID}} ${selectedOperator} [${selectedOption.map((option) => `${option}`).join(',')}]${logicalOperator}`;
                        } else {
                            return `{${selectedTempID}} ${selectedOperator} ${selectedOption}${logicalOperator}`;
                        }
                    }

                    return '';
                })
                .filter((condition) => condition !== '')
                .join(' ');

            const updatedQuestion = {
                ...question,
                DLFormulaText: DLFormulaText ? `(${DLFormulaText})` : '',
            };
            setShowdLText(updatedQuestion.DLFormulaText);
            // if (updatedQuestion.visibleIf === '') {
            //     setShowVisibleIfText(updatedQuestion.DLFormulaText);
            // }
            // else {
            //     setShowVisibleIfText(updatedQuestion.visibleIf);
            // }
            setShowVisibleIfText(updatedQuestion.DLFormulaText);

            setSelectedUpdatedQuestion(updatedQuestion);
        };

        updateDLFormulaText();
    }, [conditions, question]);


    const handleAddCondition = () => {
        setConditions([...conditions, {}]);

    };

    const handleSelectChange = (event, index) => {
        const selectedTempId = event.target.value;
        const selectedObj = newList.find((item) => item.tempId === selectedTempId);

        setConditions((prevConditions) => {
            const updatedConditions = [...prevConditions];
            updatedConditions[index] = {
                ...updatedConditions[index],
                selectedTempID: selectedTempId,
                selectedOperator: '',
                selectedOption: '',
                selectedLogicalOperator: '',
                selectedQuestion: selectedObj
            };
            return updatedConditions;
        });
        setAnyChange(true);

    };

    const handleOperatorChange = (event, index) => {
        const { value } = event.target;
        setConditions((prevConditions) => {
            const updatedConditions = [...prevConditions];
            updatedConditions[index] = { ...updatedConditions[index], selectedOperator: value };
            return updatedConditions;
        });
        setAnyChange(true);
    };

    const handleLogicalOperatorChange = (event, index) => {
        const { value } = event.target;
        setConditions((prevConditions) => {
            const updatedConditions = [...prevConditions];
            updatedConditions[index] = { ...updatedConditions[index], selectedLogicalOperator: value };
            return updatedConditions;
        });
        setAnyChange(true);

    };

    const handleDeleteCondition = (index) => {
        setConditions((prevConditions) => {
            const updatedConditions = [...prevConditions];
            updatedConditions.splice(index, 1);
            return updatedConditions;
        });
        setAnyChange(true);

    };

    const validateConditionString = (conditionString) => {
        const trimmedString = conditionString.trim(); // Trim leading and trailing whitespace
        const stack = [];
        const logicalOperators = ['and', 'or'];

        const regex = /\(|\)|\band\b|\bor\b|\[.*?\]|{[^}]*}|[^\s()]+/g;
        const tokens = trimmedString.match(regex);

        if (!tokens) {
            return false; // No valid tokens found
        }

        for (let i = 0; i < tokens.length; i++) {
            const token = tokens[i];

            if (token === '(') {
                stack.push(token);
            } else if (token === ')') {
                if (stack.length === 0 || stack.pop() !== '(') {
                    return false; // Unbalanced parenthesis
                }
            } else if (logicalOperators.includes(token)) {
                if (i === 0 || i === tokens.length - 1) {
                    return false; // Unexpected logical operator at the beginning or end
                }

                const prevToken = tokens[i - 1];
                const nextToken = tokens[i + 1];

                if (prevToken === '(' || nextToken === ')' || logicalOperators.includes(prevToken) || logicalOperators.includes(nextToken)) {
                    return false; // Unexpected logical operator with adjacent parentheses or operators
                }
            } else if (token.startsWith('{') && token.endsWith('}')) {
                const conditionParts = token.substring(1, token.length - 1).split('=');

                if (conditionParts.length !== 2 || conditionParts.some(part => part.trim() === '')) {
                    return false; // Invalid condition format
                }
            } else if (token.startsWith('[') && token.endsWith(']')) {
                const value = token.substring(1, token.length - 1).trim();

                if (value === '') {
                    return false; // Empty value
                }
            } else {
                return false; // Unknown token
            }
        }

        return stack.length === 0; // All parentheses are balanced
    };


    const handleSaveDL = () => {
        // const isDLValid = validateConditionString(showdLText);
        // const isVisibleIfValid = validateConditionString(showVisibleIfText);

        // if (isDLValid && isVisibleIfValid) {
        //     let obj = { ...selectedUpdatedQuestion };
        //     obj.DLFormulaText = showdLText;
        //     obj.visibleIf = showVisibleIfText;
        //     saveDLForEachQuestion(obj);
        // } else {
        //     console.log("Please check the DisplayLogic and/or VisibleIf condition. They cannot be saved!");
        // }
        let obj = { ...selectedUpdatedQuestion };
        obj.DLFormulaText = showdLText;
        obj.visibleIf = showVisibleIfText;
        saveDLForEachQuestion(obj);
        setAnyChange(false);
    };



    const handleOptionChange = (index, value) => {
        setConditions((prevConditions) => {
            const updatedConditions = [...prevConditions];
            updatedConditions[index] = {
                ...updatedConditions[index],
                selectedOption: value,
            };
            return updatedConditions;
        });
        setAnyChange(true);
    };
    function findStringDifference(str1, str2) {
        let difference = '';
        const maxLength = Math.max(str1.length, str2.length);

        for (let i = 0; i < maxLength; i++) {
            if (str1[i] !== str2[i]) {
                if (str2[i] === '(' || str2[i] === ')') {
                    difference += str2[i];
                } else {
                    break; // Stop comparing if non-parentheses character is encountered
                }
            }
        }

        return difference;
    }


    const handleDLInputChange = (e) => {
        const inputValue = e.target.value;
        const difference = findStringDifference(showVisibleIfText, inputValue);

        if (difference === "(" || difference === ")") {
            setShowVisibleIfText(inputValue);
        } else if (difference === "" && inputValue.length < showVisibleIfText.length) {
            setShowVisibleIfText(inputValue);
        }
    };

    return (
        <div style={{ marginTop: '5px' }}>
            <div className="row">
                <div className="col-12">
                    <p><i>Changing the order or deletion of selected question will reset the display logic.</i></p>
                </div>
            </div>
            <div className="row">
                <div className="col-9">
                    <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                        <span className="tab-view-label"
                            style={{ 'fontWeight': 'bold', 'fontSize': '14px', 'marginBottom': '3px' }}>
                            Display Logic For the current question :</span>
                        <span style={{ 'fontWeight': 'bold', 'fontSize': '14px', 'marginBottom': '3px' }}>
                            {question.tempId + ". "}{question.questionText}</span>
                    </div>
                </div>
                <div className="col-3">
                    <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                        <CustomButton
                            name="Save"
                            label="Validate"
                            className="custom-primary-button"
                            style={{
                                backgroundColor: '#68b828',
                                height: '30px',
                            }}
                            icon="pi pi-save"
                            onClick={handleSaveDL}
                            disabled={!anyChange}
                        />
                    </div>

                </div>
            </div>

            <span>Validated Display Logic   : <span style={{ 'fontWeight': 'bold' }}>{selectedUpdatedQuestion && selectedUpdatedQuestion?.visibleIf}</span></span>

            <div className="row">
                <div className="col-2">
                    <span>Display Logic:</span>
                </div>
                <div className="col-10">
                    <InputTextarea
                        style={{
                            'fontSize': 'normal',
                            'minWidth': '100%',
                            maxWidth: 'fit-content',
                            marginRight: '5px',
                        }}
                        rows={2}
                        autoResize={true}
                        value={showVisibleIfText}
                        onChange={handleDLInputChange}

                    />
                </div>
            </div>
            <div className="mb-2 mt-1" style={{
                backgroundColor: 'black',
                height: 2
            }}></div>
            {conditions.map((condition, index) => (
                <div>
                    <div className="row" key={index} style={{ marginBottom: '5px' }}>
                        <div className="col-7">
                            <label className="tab-view-label"
                                style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '3px' }}
                                htmlFor={`questionDropdown_${index}`}>Select a question:</label>
                            <select
                                id={`questionDropdown_${index}`}
                                className="form-select"
                                value={condition.selectedTempID || ''}
                                onChange={(event) => handleSelectChange(event, index)}
                            >
                                <option value="">Select a question</option>
                                {newList.map((item) => (
                                    <option key={item.tempId} value={item.tempId}>
                                        {item.tempId + '. ' + item.questionText}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {condition.selectedTempID &&
                            <div className="col-3">
                                <label className="tab-view-label"
                                    style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '3px' }}
                                    htmlFor={`operatorDropdown_${index}`}>Select an operator:</label>
                                <select
                                    id={`operatorDropdown_${index}`}
                                    className="form-select"
                                    value={condition.selectedOperator || ''}
                                    onChange={(event) => handleOperatorChange(event, index)}
                                >
                                    <option value="">Select an operator</option>
                                    {operators.map((item) => (
                                        <option key={item.value} value={item.value}>
                                            {item.text}
                                        </option>
                                    ))}
                                </select>
                            </div>}

                        <div className="col-2 align-self-center mt-4">
                            <CustomButton
                                name="RemoveOption"
                                label=""
                                className="p-button-danger margin-right"
                                icon="pi pi-times"
                                style={{
                                    backgroundColor: '#7c38bc',
                                    borderColor: '#7c38bc',
                                    height: '30px',
                                    lineHeight: '1.5',
                                    padding: '0 0.5rem',
                                    fontSize: '1rem',
                                }}
                                onClick={() => handleDeleteCondition(index)}
                            />
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div>
                                    <span className="tab-view-label"
                                        style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '3px' }}>
                                        is :
                                    </span>
                                </div>
                                {function () {
                                    if (condition.selectedQuestion)
                                        switch (condition.selectedQuestion.type) {
                                            case 'numeric':
                                                return (
                                                    <NumericOptions
                                                        {...condition}
                                                        onChange={(value) =>
                                                            handleOptionChange(index, value)
                                                        }
                                                    />
                                                );
                                            case 'NPS':
                                                return (
                                                    <NPSOptions
                                                        {...condition}
                                                        onChange={(value) =>
                                                            handleOptionChange(index, value)
                                                        }
                                                    />
                                                );
                                            case 'openText':
                                                return <OpenTextOptions
                                                    {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'starRating':
                                                return <StarRankOptions
                                                    {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'rating':
                                                return <EmojiRankOptions
                                                    {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'radiogroup':
                                                return <MultiselectDropdownWithValue  {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'checkbox':
                                                return <MultiselectDropdownWithValue  {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'date':
                                                return <DateOptions  {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'selectList':
                                                return <MultiselectDropdownWithArr  {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'ranking':
                                                return <MultiselectDropdownWithArr  {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'singleDropdown':
                                                return <MultiselectDropdownWithValue  {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'multiselectdropdown':
                                                return <MultiselectDropdownWithValue  {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'boolean':
                                                return <BooleanDropdown  {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;
                                            case 'sliderScale':
                                                return <SliderQuestion  {...condition}
                                                    onChange={(value) =>
                                                        handleOptionChange(index, value)
                                                    } />;

                                            // case 'imagepicker':
                                            //     return <ImageSelectionQuestion  {...condition}
                                            //         onChange={(value) =>
                                            //             handleOptionChange(index, value)
                                            //         } />;

                                            default:
                                                return <></>;
                                        }
                                }()}



                                <div className="col-4 align-self-center mt-3">
                                    <label className="tab-view-label"
                                        style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '3px' }}
                                        htmlFor={`logicalOperatorDropdown_${index}`}>Select a Logical Operator:</label>
                                    <select
                                        id={`logicalOperatorDropdown_${index}`}
                                        className="form-select"
                                        value={condition.selectedLogicalOperator || ''}
                                        onChange={(event) => handleLogicalOperatorChange(event, index)}
                                    >
                                        <option value="">Select a logical operator</option>
                                        {logicalOperators.map((item) => (
                                            <option key={item.value} value={item.value}>
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className='row'>
                <div className='col-3 mt-3'>
                    <CustomButton
                        name="add"
                        label="Add condition"
                        className="p-button-success margin-right"
                        icon="pi pi-plus"
                        style={{
                            backgroundColor: '#7c38bc',
                            borderColor: '#7c38bc',
                            height: '30px',
                            lineHeight: '1.5',
                            padding: '0 0.5rem',
                            fontSize: '1rem',
                        }}
                        onClick={handleAddCondition}
                    />
                </div>

            </div>

        </div>
    );
};


const NumericOptions = (props) => {
    const { selectedQuestion, selectedOption, onChange } = props;

    const handleOptionChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <>
            <div className="p-inputtextarea" style={{ width: '75%' }}>
                <input
                    name="numeric"
                    type="number"
                    className="p-inputtext p-component"
                    style={{ width: '100%' }}
                    value={selectedOption}
                    onChange={handleOptionChange}
                />
            </div>
        </>
    );
};
// NPS DATA
const npsData = [
    { value: '0', className: 'p-button-danger margin-right' },
    { value: '1', className: 'p-button-danger margin-right' },
    { value: '2', className: 'p-button-danger margin-right' },
    { value: '3', className: 'p-button-danger margin-right' },
    { value: '4', className: 'p-button-danger margin-right' },
    { value: '5', className: 'p-button-danger margin-right' },
    { value: '6', className: 'p-button-danger margin-right' },
    { value: '7', className: 'p-button-warning margin-right' },
    { value: '8', className: 'p-button-warning margin-right' },
    { value: '9', className: 'p-button-success margin-right' },
    { value: '10', className: 'p-button-success margin-right' }
];
// NpS Question
const NPSOptions = (props) => {
    const { selectedQuestion, selectedOption, onChange } = props;
    const handleOptionChange = (value) => {
        onChange(value);
    };

    return (
        <>
            <div className="option" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                {
                    npsData.map((element) => (
                        <CustomButton
                            key={element.value}
                            name={'nps' + element.value}
                            label={element.value}
                            className={element.className}
                            style={{ height: '40px', minWidth: '40px', margin: '0 5px' }}
                            onClick={() => handleOptionChange(element.value)}
                        />
                    ))
                }
            </div>

        </>
    )
}

// Open Text Question
const OpenTextOptions = (props) => {
    const { selectedQuestion, selectedOption, onChange } = props;
    const handleOptionChange = (event) => {
        onChange(event.target.value);
    };
    return (
        <>
            <div className="p-inputtextarea" style={{ width: '75%' }}>
                <input
                    name="numeric"
                    className="p-inputtext p-component"
                    style={{ width: '100%' }}
                    value={selectedOption}
                    onChange={handleOptionChange}
                />
            </div>
        </>
    )
}

//Emoji Question
const EmojiRankOptions = (props) => {
    const { selectedQuestion, selectedOption, onChange } = props;
    const [value, setValue] = useState(selectedOption);

    const handleRatingChange = (value) => {
        setValue(value);
        onChange(value);
    };
    const smileyList = ["😞", "😕", "😐", "😊", "😄", "😃", "🤩"];

    const renderSmileys = () => {
        const smileys = [];
        for (let i = 1; i <= selectedQuestion.rateMax; i++) {
            const index = Math.floor((i - 1) / (selectedQuestion.rateMax / smileyList.length));
            const isSelected = i === parseInt(selectedOption);
            smileys.push(
                <Button
                    key={i}
                    variant="light"
                    className={`mx-1 ${isSelected ? 'bg-primary text-white' : ''}`}
                    onClick={() => handleRatingChange(i)}
                >
                    {smileyList[index]}
                </Button>
            );
        }
        return smileys;
    };


    return (
        <>
            <div className="d-flex align-items-center">{renderSmileys()}</div>
        </>
    )
}
// Star Question
const StarRankOptions = (props) => {
    const { selectedQuestion, selectedOption, onChange } = props;
    const [value, setValue] = useState(selectedOption);

    const handleRatingChange = (value) => {
        setValue(value);
        onChange(value);

    };

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= selectedQuestion.rateMax; i++) {
            stars.push(
                <Button
                    key={i}
                    variant="light"
                    className="mx-1"
                    onClick={() => handleRatingChange(i)}

                >
                    <i
                        className={`bi bi-star${parseInt(selectedOption) >= i ? "-fill" : ""} text-warning`}
                    ></i>
                </Button>
            );
        }
        return stars;
    };
    return (
        <>
            <div className="d-flex align-items-center">{renderStars()}</div>
        </>
    )
}


//slider question
const SliderQuestion = (props) => {
    const { selectedQuestion, selectedOption, onChange } = props;
    const [valueInput, setValueInput] = useState(selectedOption);

    const [value, setValue] = useState(selectedQuestion.minValue);

    const handleInputChange = (event) => {
        const newValue = parseInt(event.target.value);
        setValue(newValue);
        onChange(newValue);
    };
    const sliderStyle = {
        width: "100%",
        background:
            "linear-gradient(to right, #87CEFA, #87CEFA " +
            ((value - selectedQuestion.minValue) / (selectedQuestion.maxValue - selectedQuestion.minValue)) * 100 +
            "%, #f5f5f5 " +
            ((value - selectedQuestion.minValue) / (selectedQuestion.maxValue - selectedQuestion.minValue)) * 100 +
            "%, #f5f5f5)",
    };

    const handleDecreaseClick = () => {
        const newValue = value - selectedQuestion.step;
        if (newValue >= selectedQuestion.minValue) {
            setValue(newValue);
        }
    };

    const handleIncreaseClick = () => {
        const newValue = value + selectedQuestion.step;
        if (newValue <= selectedQuestion.maxValue) {
            setValue(newValue);
        }
    };
    return (
        <>
            <div style={{ width: '100%' }}>

                <div className="d-flex align-items-center">
                    <div className="col-1">
                        <Button variant="outline-secondary" onClick={handleDecreaseClick}>
                            -
                        </Button>
                    </div>
                    <div className="justify-content-center mb-3 align-items-center col-10">
                        <div className="text-center">
                            <div className="mx-2">{valueInput || value}</div>
                            <input
                                type="range"
                                name={selectedQuestion.name}
                                id={selectedQuestion.name}
                                min={selectedQuestion.minValue}
                                max={selectedQuestion.maxValue}
                                step={selectedQuestion.step}
                                value={valueInput || value}
                                onChange={handleInputChange}
                                className="form-control-range"
                                style={sliderStyle}
                            />
                        </div>
                    </div>

                    <div className="col-1">
                        <Button variant="outline-secondary" onClick={handleIncreaseClick}>
                            +
                        </Button>
                    </div>
                </div>

            </div>
        </>
    )
}


//Date Question
const DateOptions = (props) => {
    const { selectedQuestion, selectedOption, onChange } = props;

    const [value, setValue] = useState(new Date(selectedOption)); // Convert selectedOption to a Date object

    const handleOptionChange = (event) => {
        setValue(event.value); // Update the selected date in the state
        onChange(event.value.toLocaleDateString('en-US')); // Call the onChange callback with the formatted date
    };

    return (
        <>
            <div className="col-5" style={{ padding: 0 }}>
                <div className='custom-calender'>
                    <Calendar
                        value={value}
                        onChange={handleOptionChange}
                        placeholder="Example Date"
                        name="exampleDate"
                        showIcon={true}
                        showButtonBar={true}
                    />
                </div>
            </div>
        </>
    );
};

// question which has with text and value as props for options
const MultiselectDropdownWithValue = ({ selectedQuestion, onChange, selectedOption }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedChoices, setSelectedChoices] = useState([]);

    useEffect(() => {
        if (selectedOption && Array.isArray(selectedOption)) {
            setSelectedChoices(selectedOption);
        }
    }, [selectedOption]);

    const handleSelectOption = (event) => {
        const selectedValue = event.target.value;
        const selectedArray = [...selectedChoices];
        if (selectedArray.includes(selectedValue)) {
            selectedArray.splice(selectedArray.indexOf(selectedValue), 1);
        } else {
            selectedArray.push(selectedValue);
        }
        setSelectedChoices(selectedArray);
        console.log(selectedArray, "selectedArray")
        onChange(selectedArray);
    };

    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSelectAll = () => {
        const allValues = selectedQuestion.choices.map((choice) => choice.value);
        setSelectedChoices(allValues);
        onChange(allValues);

    };

    const handleDeselectAll = () => {
        setSelectedChoices([]);
    };

    const sortedChoices = selectedQuestion.choices
        .slice()
        .sort((a, b) => a.text.localeCompare(b.text))
        .reduce(
            (acc, choice) => {
                if (selectedChoices.includes(choice.value)) {
                    acc.selected.push(choice);
                } else {
                    acc.unselected.push(choice);
                }
                return acc;
            },
            { selected: [], unselected: [] }
        );

    const allChoices = sortedChoices.selected.concat(sortedChoices.unselected);

    const maxWebChoices = 3;

    return (

        <Dropdown
            style={{ width: '80%' }}
            show={showDropdown}
            onToggle={handleToggleDropdown}
        >
            <Dropdown.Toggle
                variant="secondary"
                className="w-100"
                id={`${selectedQuestion.name}-dropdown`}
                style={{
                    backgroundColor: "white",
                    color: "black",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "left",
                }}
            >
                {selectedChoices.length === 0 && (
                    <span>Select an option</span>
                )}
                {selectedChoices.length > 0 && selectedChoices.length <= maxWebChoices && (
                    <span>
                        {selectedChoices.map((selected) => (
                            <Badge
                                key={selected}
                                variant="secondary"
                                className="mr-1 mb-1 selected-choice" // Add the CSS class "selected-choice"
                                onClick={() => handleSelectOption({ target: { value: selected } })}
                            >
                                {selected} <i className="fa fa-times-circle ml-1"></i>
                            </Badge>
                        ))}
                    </span>
                )}

                {selectedChoices.length > maxWebChoices && (
                    <span>
                        {`${selectedChoices.length} items selected`}
                    </span>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="w-100"
                style={{
                    minWidth: "200px",
                    maxHeight: "300px",
                    overflowY: "auto",
                    padding: "0.5rem",
                }}
            >
                <div className="mb-2 d-flex align-items-center">
                    <Form.Check
                        type="checkbox"
                        label="Select All"
                        onChange={handleSelectAll}
                        checked={selectedChoices.length === allChoices.length}
                    />
                </div>
                {allChoices.map((choice) => (
                    <div
                        key={choice.value}
                        className="d-flex align-items-center justify-content-between w-100"
                    >
                        <label htmlFor={`checkbox-${choice.value}`} className="w-100">
                            <Form.Check
                                type="checkbox"
                                label={choice.text}
                                value={choice.value}
                                id={`checkbox-${choice.value}`}
                                onChange={handleSelectOption}
                                checked={selectedChoices.includes(choice.value)}
                            />
                        </label>
                    </div>
                ))}
            </Dropdown.Menu>
        </Dropdown>

    );
};

// question which is an array only for options
const MultiselectDropdownWithArr = ({ selectedQuestion, onChange, selectedOption }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedChoices, setSelectedChoices] = useState([]);

    useEffect(() => {
        if (selectedOption) {
            setSelectedChoices(selectedOption);
        }
    }, [selectedOption]);

    const handleSelectOption = (event) => {
        const selectedValue = event.target.value;
        const selectedArray = [...selectedChoices];
        if (selectedArray.includes(selectedValue)) {
            selectedArray.splice(selectedArray.indexOf(selectedValue), 1);
        } else {
            selectedArray.push(selectedValue);
        }
        setSelectedChoices(selectedArray);
        onChange(selectedArray);
    };

    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSelectAll = () => {
        const allValues = selectedQuestion.choices;
        setSelectedChoices(allValues);
        onChange(allValues);
    };

    const handleDeselectAll = () => {
        setSelectedChoices([]);
    };

    const splitAndTrimChoices = selectedQuestion.choices[0].split(',').map((choice) => choice.trim());

    const sortedChoices = splitAndTrimChoices
        .slice()
        .sort((a, b) => a.localeCompare(b))
        .reduce(
            (acc, choice) => {
                if (selectedChoices.includes(choice)) {
                    acc.selected.push(choice);
                } else {
                    acc.unselected.push(choice);
                }
                return acc;
            },
            { selected: [], unselected: [] }
        );

    const allChoices = sortedChoices.selected.concat(sortedChoices.unselected);

    const maxWebChoices = 3;

    return (
        <Dropdown style={{ width: '80%' }} show={showDropdown} onToggle={handleToggleDropdown}>
            <Dropdown.Toggle
                variant="secondary"
                className="w-100"
                id={`${selectedQuestion.name}-dropdown`}
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                }}
            >
                {selectedChoices.length === 0 && <span>Select an option</span>}
                {selectedChoices.length > 0 && selectedChoices.length <= maxWebChoices && (
                    <span>
                        {selectedChoices.map((selected) => (
                            <Badge
                                key={selected}
                                variant="secondary"
                                className="mr-1 mb-1 selected-choice" // Add the CSS class "selected-choice"
                                onClick={() => handleSelectOption({ target: { value: selected } })}
                            >
                                {selected} <i className="fa fa-times-circle ml-1"></i>
                            </Badge>
                        ))}
                    </span>
                )}
                {selectedChoices.length > maxWebChoices && (
                    <span>{`${selectedChoices.length} items selected`}</span>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="w-100"
                style={{
                    minWidth: '200px',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    padding: '0.5rem',
                }}
            >
                <div className="mb-2 d-flex align-items-center">
                    <Form.Check
                        type="checkbox"
                        label="Select All"
                        onChange={handleSelectAll}
                        checked={selectedChoices.length === allChoices.length}
                    />
                </div>
                {allChoices.map((choice) => (
                    <div
                        key={choice}
                        className="d-flex align-items-center justify-content-between w-100"
                    >
                        <label htmlFor={`checkbox-${choice}`} className="w-100">
                            <Form.Check
                                type="checkbox"
                                label={choice}
                                value={choice}
                                id={`checkbox-${choice}`}
                                onChange={handleSelectOption}
                                checked={selectedChoices.includes(choice)}
                            />
                        </label>
                    </div>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};


// Boolean question

const BooleanDropdown = ({ selectedQuestion, onChange, selectedOption }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedChoice, setSelectedChoice] = useState(selectedOption);

    useEffect(() => {
        setSelectedChoice(selectedOption);
    }, [selectedOption]);

    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSelectOption = (choice) => {
        setSelectedChoice(choice);
        onChange(choice);
        setShowDropdown(false);
    };

    return (
        <Dropdown style={{ width: '80%' }} show={showDropdown} onToggle={handleToggleDropdown}>
            <Dropdown.Toggle
                variant="secondary"
                className="w-100"
                id={`${selectedQuestion.name}-dropdown`}
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                }}
            >
                {selectedChoice ? selectedChoice : 'Select an option'}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
                <div
                    className="d-flex align-items-center justify-content-between w-100"
                    onClick={() => handleSelectOption(selectedQuestion.labelTrue)}
                >
                    <label className="w-100">
                        <Form.Check
                            type="checkbox"
                            label={selectedQuestion.labelTrue}
                            value={selectedQuestion.labelTrue}
                            checked={selectedChoice === selectedQuestion.labelTrue}
                            onChange={() => { }}
                        />
                    </label>
                </div>
                <div
                    className="d-flex align-items-center justify-content-between w-100"
                    onClick={() => handleSelectOption(selectedQuestion.labelFalse)}
                >
                    <label className="w-100">
                        <Form.Check
                            type="checkbox"
                            label={selectedQuestion.labelFalse}
                            value={selectedQuestion.labelFalse}
                            checked={selectedChoice === selectedQuestion.labelFalse}
                            onChange={() => { }}
                        />
                    </label>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};







export default DisplayLogicQuestion;
