import axios from "axios";
import { API_END_POINT } from "../../../../config";
import { startSpinner, stopSpinner } from "../../../shared-components/custom-spinner/custom-spinner-store/spinner-actions";
import { TestAction, getSurveyDefinition } from "./design-actions-creators";
import { loadToaster } from "../../../shared-components/toaster/toaster-actions-creators";

export const fetchTestCall = () => {
    return (dispatch) => {
        try {
            // dispatch(startSpinner());
            dispatch(TestAction("ravikrishnapunnam@gmail.com"));

        } catch (err) {

        }

    };
};
export const createOrUpdateSurveyDefinition = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await axios.post(`${API_END_POINT.BASE_URL}/api/Survey/PostSurveyDefinition`, postObject);
            console.log(response.data,"post Call response");
            dispatch(loadToaster({ type: 'success', title: 'Saved Successfully!!', msg: '' }))

            dispatch(stopSpinner());

        } catch (err) {
            console.error(err);
            dispatch(stopSpinner());
            throw err; // Throw the error to the caller
        }
    };
};

export const fetchSurveyDefintionListByID = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await axios.get(`${API_END_POINT.BASE_URL}/api/Survey/GetSurveyDefinitionByID/${postObject}`);
            let getDetails = response.data.data;                   
            dispatch(getSurveyDefinition(getDetails));
            dispatch(stopSpinner());

        } catch (err) {
            console.error(err);
            dispatch(stopSpinner());
            throw err; // Throw the error to the caller
        }
    };
};
