import React from 'react';
import './infoicon.css'

const InfoIcon = (props) => {
    const { msg, title = '' } = props;
    return <div >
              {msg &&
            <div className="tooltip">
                <i
                    className="fa fa-info-circle fa-lg"
                />
                <span className="tooltiptext">{msg}</span>
            </div>
        }
    </div>
}

export default InfoIcon;