import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../../shared-components/Button/custom-button';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CloneSurvey, fetchSurveyDetails } from './home-store/home-actions';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { fetchCurrentSurveyID, fetchCurrentSurveyName } from "./home-store/home-actions-creators";
import { getSurveyDefinition } from '../Design/design-store/design-actions-creators';


const HomeProjectList = () => {
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const history = useHistory();
    const { fetchProjectDetails } = useSelector((state) => state.projectList);
    const dispatch = useDispatch();
    const [showCloneDialog, setShowCloneDialog] = useState(false);
    const [surveyName, setSurveyName] = useState('');
    const [selectedSurveyCode, setSelectedSurveyCode] = useState('');
    const [selectedSurveyName, setSelectedSurveyName] = useState('');
    const [selectedSurveyID, setSelectedSurveyID] = useState('');
    const [surveyNameError, setSurveyNameError] = useState('');
    const { userLoginDetails } = useSelector((state) => state.login);


    useEffect(() => {
        const isAuthenticated = localStorage.getItem('Authentication') !== null;
        if (isAuthenticated && userLoginDetails.user_id != undefined) {
            dispatch(fetchSurveyDetails(userLoginDetails.user_id));
        }
    }, [dispatch, userLoginDetails.user_id]);


    console.log(fetchProjectDetails, "fetchProjectDetails");
    const handleSort = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };

    const sortData = (data) => {
        if (sortBy === '') {
            return data;
        }

        return data.sort((a, b) => {
            const valueA = a[sortBy];
            const valueB = b[sortBy];

            if (valueA < valueB) {
                return sortOrder === 'asc' ? -1 : 1;
            } else if (valueA > valueB) {
                return sortOrder === 'asc' ? 1 : -1;
            }

            return 0;
        });
    };

    // const sortedData = sortData(data);
    const sortedData = fetchProjectDetails && fetchProjectDetails ? sortData(fetchProjectDetails) : [];


    const getStatusColor = (survey_status_id) => {
        if (survey_status_id === 'Initiated') {
            return 'bg-warning';
        } else if (survey_status_id === 'InProgress') {
            return 'bg-success';
        } else if (survey_status_id === 'Completed') {
            return 'bg-primary';
        }
        return '';
    };

    const handleEdit = (itemId) => {
        // Navigate to the design route with the item ID
        // history.push(`/design/${itemId}`);
        const selectedSurvey = fetchProjectDetails.find(item => item.survey_id === itemId);
        if (selectedSurvey) {
            dispatch(fetchCurrentSurveyID(selectedSurvey.survey_id));
            dispatch(fetchCurrentSurveyName(selectedSurvey.survey_name));
        }
        dispatch(getSurveyDefinition({}));
        const routePath = `/surveys/edit-survey/${selectedSurvey.survey_id}/design`;
        history.push(routePath);

    };
    const createSurvey = () => {
        dispatch(fetchCurrentSurveyID(null));
        dispatch(fetchCurrentSurveyName(null));
        dispatch(getSurveyDefinition({}));
        history.push(`/surveys/create-survey`);

    };

    const hideDialog = () => {
        setShowCloneDialog(false);
        setSurveyName('');
        setSurveyNameError('');
    }
    const clone = () => {
        if (surveyName.trim() === '') {
            setSurveyNameError('Please enter a new survey name');
            return;
        }
        let postObject = {
            source_survey_id: selectedSurveyID,
            survey_name: surveyName,
            created_by: userLoginDetails.user_id
        }
        console.log(postObject)
        dispatch(CloneSurvey(postObject));
        hideDialog();

    };
    const renderFooter = () => {
        return (
            <div>
                <Button name='yes' label="Clone" icon="pi pi-check" onClick={clone} />
                <Button name='no' label="Cancel" icon="pi pi-times" onClick={hideDialog} className="p-button-secondary" />
            </div>
        );
    }
    const nameChange = (e) => {
        setSurveyName(e.target.value);
    }

    const handleClone = (itemId) => {
        const selectedSurvey = fetchProjectDetails.find(item => item.survey_id === itemId);
        if (selectedSurvey) {
            setSelectedSurveyCode(selectedSurvey.survey_code);
            setSelectedSurveyName(selectedSurvey.survey_name);
            setSelectedSurveyID(selectedSurvey.survey_id)
        }
        setShowCloneDialog(true);
    };

    function handleSurveyCodeClick(item) {
        dispatch(fetchCurrentSurveyID(item.survey_id));
        dispatch(fetchCurrentSurveyName(item.survey_name));
        const routePath = `/surveys/edit-survey/${item.survey_id}/dashboard`;
        history.push(routePath);
    }

    return (
        <div className='row'>
            <div className='col-1'></div>
            <div className='col-10'
                style={{
                    border: '1px solid #d3caca',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    color: 'black',
                    margin: '7px',
                }}
            >
                <div className="d-flex justify-content-between py-3">
                    <div className="col-md-auto">
                        <div className="row align-items-center" style={{ fontWeight: 'bold', margin: 0 }}>
                            <div className="col-md-auto">
                                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Survey List</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-auto" style={{ textAlign: 'right' }}>
                        <Button
                            name="Save"
                            label="Create Survey"
                            className="p-button-dark-blue"
                            style={{ backgroundColor: '#1F3BB3', fontSize: '12px' }}
                            icon="pi pi-plus"
                            onClick={createSurvey}
                        />
                    </div>
                </div>
                <div
                    className="mb-2 mt-1"
                    style={{
                        backgroundColor: 'black',
                        height: 1,
                    }}
                ></div>

                <div className="container">
                    <table className="table">
                        <thead>
                            <tr>

                                <th onClick={() => handleSort('survey_code')} className="text-start">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>Code</span>
                                        <div>
                                            {sortBy === 'survey_code' && sortOrder === 'asc' && <i className="pi pi-caret-up" />}
                                            {sortBy === 'survey_code' && sortOrder === 'desc' && <i className="pi pi-caret-down" />}
                                            {sortBy !== 'survey_code' && <i className="pi pi-sort" />}
                                        </div>
                                    </div>
                                </th>
                                <th onClick={() => handleSort('survey_name')} className="text-start">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>Name</span>
                                        <div>
                                            {sortBy === 'survey_name' && sortOrder === 'asc' && <i className="pi pi-caret-up" />}
                                            {sortBy === 'survey_name' && sortOrder === 'desc' && <i className="pi pi-caret-down" />}
                                            {sortBy !== 'survey_name' && <i className="pi pi-sort" />}
                                        </div>
                                    </div>
                                </th>
                                <th onClick={() => handleSort('start_date')} className="text-start">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>Start Date</span>
                                        <div>
                                            {sortBy === 'start_date' && sortOrder === 'asc' && <i className="pi pi-caret-up" />}
                                            {sortBy === 'start_date' && sortOrder === 'desc' && <i className="pi pi-caret-down" />}
                                            {sortBy !== 'start_date' && <i className="pi pi-sort" />}
                                        </div>
                                    </div>
                                </th>
                                <th onClick={() => handleSort('end_date')} className="text-start">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>End Date</span>
                                        <div>
                                            {sortBy === 'end_date' && sortOrder === 'asc' && <i className="pi pi-caret-up" />}
                                            {sortBy === 'end_date' && sortOrder === 'desc' && <i className="pi pi-caret-down" />}
                                            {sortBy !== 'end_date' && <i className="pi pi-sort" />}
                                        </div>
                                    </div>
                                </th>
                                <th onClick={() => handleSort('budget')} className="text-start">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>Completed</span>
                                        <div>
                                            {sortBy === 'budget' && sortOrder === 'asc' && <i className="pi pi-caret-up" />}
                                            {sortBy === 'budget' && sortOrder === 'desc' && <i className="pi pi-caret-down" />}
                                            {sortBy !== 'budget' && <i className="pi pi-sort" />}
                                        </div>
                                    </div>
                                </th>
                                <th onClick={() => handleSort('survey_status_id')} className="text-start">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>Status</span>
                                        <div>
                                            {sortBy === 'survey_status_id' && sortOrder === 'asc' && <i className="pi pi-caret-up" />}
                                            {sortBy === 'survey_status_id' && sortOrder === 'desc' && <i className="pi pi-caret-down" />}
                                            {sortBy !== 'survey_status_id' && <i className="pi pi-sort" />}
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">Actions</th>



                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ verticalAlign: 'middle', cursor: 'pointer' }} className="text-left">
                                        <span style={{
                                            textDecoration: 'underline',
                                            color: '#00BFFF'
                                        }}
                                            onClick={() => handleSurveyCodeClick(item)}>{item.survey_code}</span></td>
                                    <td style={{ verticalAlign: 'middle' }} className="text-left">{item.survey_name}</td>
                                    <td style={{ verticalAlign: 'middle' }} className="text-left">{item.start_date}</td>
                                    <td style={{ verticalAlign: 'middle' }} className="text-left">{item.end_date}</td>
                                    <td style={{ verticalAlign: 'middle' }} className="text-left">{item.budget}</td>
                                    <td className="text-left" style={{ verticalAlign: 'middle' }}>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '20px',
                                                textAlign: 'center',
                                                // fontWeight: 'bold', 
                                                color: 'white'
                                            }}
                                            className={getStatusColor(item.survey_status_id)}
                                        >
                                            {item.survey_status_id}
                                        </span>
                                    </td>

                                    <td className="text-center">
                                        <Button
                                            icon="pi pi-pencil"
                                            className="p-button-dark-blue m-1"
                                            onClick={() => handleEdit(item.survey_id)}
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-red m-1"
                                        // onClick={() => handleDelete(item.survey_id)}
                                        />
                                        <Button
                                            icon="pi pi-copy"
                                            className="custom-accent-button m-1"
                                            onClick={() => handleClone(item.survey_id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Dialog
                        header="Clone Project"
                        visible={showCloneDialog}
                        style={{ width: '45vw', overflow: 'none' }}
                        contentStyle={{ height: '150px' }}
                        onHide={hideDialog}
                        maximizable={false}
                        footer={renderFooter()}
                        className="clone-project" // Add the custom class to the Dialog component
                    >
                        <div className="clone-project">
                            <div className="row mt-3">
                                <div className="col-5">
                                    <div>
                                        <span
                                            // className="text-view-label"
                                            style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '3px' }}
                                        >
                                            Selected Project:
                                        </span>
                                    </div>
                                    <b style={{ color: '#1F3BB3', fontSize: 14, marginTop: '2px' }}>
                                        {selectedSurveyCode} - {selectedSurveyName}
                                    </b>
                                </div>
                                <div className="col-7">
                                    <div>
                                        <span
                                            // className="text-view-label"
                                            style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '3px' }}
                                        >
                                            New Survey Name
                                            <span style={{ fontWeight: 600, color: '#b21dac', paddingLeft: 3 }}>*</span>:
                                        </span>
                                    </div>
                                    <InputTextarea
                                        style={{ fontSize: 'normal', width: '100%' }}
                                        name="title"
                                        placeholder="Enter New Survey Name"
                                        rows={1}
                                        onChange={nameChange}
                                        value={surveyName}
                                        autoResize={true}
                                    />
                                    {surveyNameError && <span className="error-message">{surveyNameError}</span>}
                                </div>
                            </div>
                        </div>
                    </Dialog>


                </div>
            </div>
            <div className='col-1'></div>

        </div>
    );
};

export default HomeProjectList;
