import { TEST, POST_SURVEY_DETAIL_BY_ID, FETCH_SURVEY_DETAIL_BY_ID } from "./details-store-constants";

const initialState = {
    test: "test123",
    getSurveyByID: {}
};

const DetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload.test
            };
        case FETCH_SURVEY_DETAIL_BY_ID:
            return {
                ...state,
                getSurveyByID: action.payload.getSurveyByID
            };
        default:
            return state;
    }
};


export default DetailsReducer;
