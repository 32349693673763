import { FETCH_PROJECT_LIST, FETCH_SURVEY_ID, FETCH_SURVEY_NAME, TEST, } from "./home-store-constants";

const initialState = {
    test: "test123",
    fetchProjectDetails: [],
    currentSurveyID: null,
    currentSurveyName: null
};

const ProjectListReducer = (state = initialState, action) => {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload.test
            };
        case FETCH_PROJECT_LIST:
            return {
                ...state,
                fetchProjectDetails: action.payload.fetchProjectDetails
            };
        case FETCH_SURVEY_ID:
            return {
                ...state,
                currentSurveyID: action.payload.currentSurveyID
            };
        case FETCH_SURVEY_NAME:
            return {
                ...state,
                currentSurveyName: action.payload.currentSurveyName
            };
        default:
            return state;
    }
};


export default ProjectListReducer;
