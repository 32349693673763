import React, { useRef } from 'react';
import './design.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  CARD: 'question',
};

const Question = (props) => {
  const { id, index, currentPage, moveQuestion, question, className, onClick, clone, remove, callConfirmation, copyToSingleAndViceVersa } = props
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'question',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveQuestion(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'question', id, index },
    type: 'question', // Add this line
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });


  const opacity = isDragging ? 0 : 1;
  // this is for drag Question 
  // drag(drop(ref));

  return (
    question ? <div ref={question.isTemplate ? null : ref}
      style={question.isTemplate ? {} : { opacity }}
      key={question.tempId} className={className}
      // onClick={() => onClick(index)}
      onClick={() => {
        props.setActiveHeaderFooter('question');
        props.onClick(index);
      }}
    >


      {/* <i ref={ref} className={"fa fa-arrows-v hidden-icon move"} /> */}
      <div className="question-label"
      // style={question.isTemplate ? { marginLeft: 0 } : {}}
      >
        {question.errorStatus && question.errorStatus === true ?
            <i style={{ color: '#D32F2F', marginRight: 5  }}
              className="fa fa-exclamation-circle"></i>
         : <></>}
        <i className={question?.selectedType?.icon + " icon"}
        // style={{ marginRight: 5 }}
        />

        <div className={`question-text`}>Q{currentPage + 1}-{index + 1}.{question.questionText}</div>
        {" ... "}
        {/* <i className={"fa fa-clone hidden-icon"} style={{ marginLeft: 5 }}
                // onClick={(e) => { clone(e, question) }} 
                /> */}
        <i className={"fa fa-trash-o hidden-icon"}
          // style={{ marginLeft: 5 }}
          onClick={(e) => remove(e, index)}
        />
      </div>
    </div> : <></>
  )
}

export default Question