import React, { useState } from 'react';
import Icon from '../../../Images/ps_logo.png';
import LogoIcon from '../../../Images/login_img.png';
import Button from '../../shared-components/Button/custom-button';

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) {
            setEmailError('Please enter your email');
        } else if (!validateEmail(email)) {
            setEmailError('Please enter a valid email');
        }

        if (!password) {
            setPasswordError('Please enter your password');
        }

        // Perform additional validation or submit the form
        if (email && password && validateEmail(email)) {
            onLogin(email, password); // Call the onLogin callback provided by the parent component
        }
    };

    const validateEmail = (email) => {
        // Simple email validation regex pattern
        const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(email);
    };

    return (
        <div className='row' style={{ overflow: 'hidden' }}>
            <div className='col-2'></div>
            <div className="col-8">
                <div style={{
                    border: '1px solid #d3caca',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    color: 'black',
                    margin: '10px',
                }}>
                    <div className="row w-100 mx-0">
                        <div className="col-lg-6 mx-auto" style={{ padding: '0px', backgroundColor: 'white' }}>
                            <img src={LogoIcon} className="mx-auto d-block" style={{ maxHeight: '95%' }} alt="Logo" />
                        </div>
                        <div className="col-lg-6 mx-auto" style={{ padding: '0px' }}>
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div>
                                    <img
                                        src={Icon}
                                        alt="Logo"
                                        style={{ maxHeight: '50px', maxWidth: '70%' }}
                                    />
                                    <br />
                                    <br />
                                </div>
                                <h4>Hello! Let's get started</h4>
                                <h6 className="fw-light">Sign in to continue.</h6>
                                <form className="pt-3" onSubmit={handleSubmit}>
                                    <div className={`form-group ${emailError ? 'has-error' : ''}`}
                                        style={{ marginBottom: '10px' }}>
                                        <input
                                            type="email"
                                            className={`form-control form-control-lg ${emailError ? 'is-invalid' : ''}`}
                                            id="userEmail"
                                            name="userEmail"
                                            placeholder="Username"
                                            value={email}
                                            onChange={handleEmailChange}
                                            style={{ fontSize: '14px' }}
                                        />
                                        {emailError && <span className="error-message">{emailError}</span>}
                                    </div>
                                    <div className={`form-group ${passwordError ? 'has-error' : ''}`}
                                        style={{ marginBottom: '10px' }}>
                                        <input
                                            type="password"
                                            className={`form-control form-control-lg ${passwordError ? 'is-invalid' : ''}`}
                                            id="userPassword"
                                            name="userPassword"
                                            placeholder="Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            style={{ fontSize: '14px' }}
                                        />
                                        {passwordError && <span className="error-message">{passwordError}</span>}
                                    </div>
                                    <div className="mt-3">
                                        <Button
                                            name="signin"
                                            label="SIGN IN"
                                            className="p-button-dark-blue"
                                            style={{ backgroundColor: '#1F3BB3', fontSize: '12px' }}
                                            type="submit"
                                        />
                                    </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                            <label className="form-check-label text-muted">
                                                <input type="checkbox" className="form-check-input" />
                                                Keep me signed in
                                                <i className="input-helper"></i>
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-2'></div>
        </div>
    );
};

export default Login;
