import { TEST, FETCH_PROJECT_LIST, FETCH_SURVEY_ID, FETCH_SURVEY_NAME } from "./home-store-constants";

export function TestAction(data) {
    return {
        type: TEST,
        payload: {
            test: data
        }
    }
}
export function fetchProjectList(data) {
    return {
        type: FETCH_PROJECT_LIST,
        payload: {
            fetchProjectDetails: data
        }
    }
}
export function fetchCurrentSurveyID(data) {
    return {
        type: FETCH_SURVEY_ID,
        payload: {
            currentSurveyID: data
        }
    }
}
export function fetchCurrentSurveyName(data) {
    return {
        type: FETCH_SURVEY_NAME,
        payload: {
            currentSurveyName: data
        }
    }
}