import React, { useEffect } from 'react'
import { InputTextarea } from 'primereact/inputtextarea';
import '../design.css'
import InfoIcon from '../../../shared-components/Info-Icon/InfoIcon';
import { validateHeader } from '../Helpers/Validations';

const DetailsContent = (props) => {
    const { dataContent, onChange, error, setError } = props;

    useEffect(() => {
        console.log("hey eeror", error);
    }, [error])

    useEffect(() => {
        if (dataContent)
            setError(validateHeader(dataContent))
        return () => { }
    }, [dataContent])

    console.log(props, "Header")
    return (<div className="">
        <div className="col-12">
            <div className="row" style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                <div>
                    <span className="tab-view-label"
                        style={{ 'fontWeight': 'bold', 'fontSize': '14px', 'marginBottom': '3px' }}>Survey Title
                        <span style={{ fontWeight: 600, color: '#b21dac', paddingLeft: 3 }}>*</span> : </span>
                    {error.title && <span className="error-message"> {error.title}</span>}
                </div>
                <InputTextarea style={{ 'fontSize': 'normal', 'marginLeft': '10px' }}
                    name="title"
                    placeholder="Enter Your Survey Title Here"
                    rows={1}
                    onChange={(e) => { onChange(e, 'title', dataContent) }}
                    value={dataContent.title} autoResize={true} />


            </div>
            <div className="row mt-3" style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                <div>
                    <span className="tab-view-label"
                        style={{ 'fontWeight': 'bold', 'fontSize': '14px', 'marginBottom': '3px' }}>Survey Description
                        {/* <span style={{ fontWeight: 600, color: '#b21dac', paddingLeft: 3 }}>*</span> */}
                         : </span>
                    {/* {error.question && <span className="error-message"> {error.question}</span>} */}
                </div>
                <InputTextarea style={{ 'fontSize': 'normal', 'marginLeft': '10px' }}
                    name="title"
                    placeholder="Enter Your Survey Description Here"
                    rows={1}
                    onChange={(e) => { onChange(e, 'description', dataContent) }}
                    value={dataContent.description} autoResize={true} />


            </div>
            <div className="row mt-3" style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                <div style={{
                    display: 'flex', alignItems: 'center'
                    // , justifyContent: 'space-between'
                }}>
                    <span className="tab-view-label" style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '3px' }}>
                        Survey Logo
                        <span style={{ fontWeight: 600, color: '#b21dac', paddingLeft: 3 }}>*</span> :
                    </span>
                    {/* <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                        <InfoIcon msg="You can paste url here" />
                    </div> */}
                    {error.logo && <span className="error-message"> {error.logo}</span>}
                </div>


                <InputTextarea style={{ 'fontSize': 'normal', 'marginLeft': '10px' }}
                    name="title"
                    placeholder="Use a URL for Logo"
                    rows={1}
                    onChange={(e) => { onChange(e, 'logo', dataContent) }}
                    value={dataContent.logo} autoResize={true} />


            </div>
        </div>

    </div>)
}

export default DetailsContent