export const isNullorEmpty = (value) => {
    switch (typeof value) {
        case "string":
            return value == null || value === undefined || value.trim() === '';
        case "number":
            return value == null || value === undefined || value === -1;
        case "object": {
            if (Array.isArray(value))
                return value == null || value === undefined || value.length === 0
            return !value;
        }
        default:
            return true;
    }
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}