import React from 'react';
import './index.css';
import { Dialog } from 'primereact/dialog';

const DisplayIcon = ({ imageData, alt, style = {}, className = '' }) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="dialog">
            <div className={className} style={style} onClick={handleClickOpen}>{<img className="display-icon" src={imageData} alt={alt} />}</div>
            {/* src={"data:image/png;base64," + imageData} */}
            <Dialog onHide={handleClose} visible={open}>
                <img className="display-icon-max" src={imageData} alt={alt} />
            </Dialog>
        </div>
    )
}

export default DisplayIcon;