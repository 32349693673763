import React, { useState } from 'react'
import './design.css';
import { SurveyQuestionTypes } from './Helpers/QuestionsModel';
import Question from './Question';

export const QuestionsList = (props) => {
  const { questions, selected,currentPage, ...functions } = props;

  return (<div className="row" style={{ 'justifyContent': 'center' }}>
    <div className="row question-list">
      {questions.map((ques, index) => {
        let className = index === selected ? "row question selected" : "row question";
        className = ques.errorStatus === true ? className + " error" : className;
        return <Question key={ques.tempId} id={ques.tempId} index={index} currentPage={currentPage}
          className={className} question={ques}
        {...functions} 
        />
      })}
    </div>
  </div>)
}



export const QuestionTypeList = (props) => {

  const { onClick, selected } = props;
  // const { questionnaireDetails } = useSelector((state) => { return state.questionnaire });
  const [displayQuestions, setDisplayQuestions] = useState([...SurveyQuestionTypes]);

  // useEffect(() => {
  //   const { questionnaireTypeId } = questionnaireDetails;
  //   if (questionnaireTypeId === 3) {
  //     setDisplayQuestions([...demographyQuestions]);
  //   }
  //   return () => { }
  // }, [questionnaireDetails]);

  return (<div className="row" style={{ 'justifyContent': 'center' }}>
    <div className="row question-type-list">
      {displayQuestions.map((ques) => {
         const style = ques.id === selected ? "question-type selected" : "question-type";
        // const style = "question-type";
        return <div key={ques.id} className={style}
          onClick={() => onClick(ques)}
        >
          <div style={{ marginRight: 10 }}><i className={ques.icon + " icon"} /></div>
          <div>{ques.label}</div>
        </div>
      })}
      {/* Questions Type LIsts */}
    </div>
  </div>)
}
