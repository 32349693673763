import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter as Router } from 'react-router-dom';



ReactDOM.render(
  <React.StrictMode>
    {/* <App/> */}

    <Router>
      <App />

    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);