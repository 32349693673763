import React, { useState, useEffect, useRef } from 'react';
import './design.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch, useSelector } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import CustomButton from '../../shared-components/Button/custom-button';
import { Dialog } from 'primereact/dialog';
import { isNullorEmpty } from '../../../utlities/validation-util';
import { Calendar } from 'primereact/calendar';
import { Button } from "react-bootstrap";
import TextEditor from '../../shared-components/TextEditor/TextEditor';
import DisplayIcon from '../../shared-components/display-icon/DisplayIcon';
import CompressedImageUpload from '../../shared-components/Image-Upload/CompressedImageUpload';
import { validateQuestionsAndOptions } from './Helpers/Validations';

const Content = (props) => {
    const { question, onChange, error, setError } = props;

    useEffect(() => {
        console.log("hey eeror", error);
    }, [error])

    useEffect(() => {
        if (question)
            setError(validateQuestionsAndOptions(question))
        return () => { }
    }, [question])


    return (<div className="">
        {question &&
            <div className="col-12">
                <div className="row" style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                    <div>
                        <span className="tab-view-label"
                            style={{ 'fontWeight': 'bold', 'fontSize': '14px', 'marginBottom': '3px' }}>Question
                            <span style={{ fontWeight: 600, color: '#b21dac', paddingLeft: 3 }}>*</span> : </span>
                        {error.question && <span className="error-message"> {error.question}</span>}
                    </div>

                    {/* <TextEditor /> */}

                    <InputTextarea style={{ 'fontSize': 'normal', 'marginLeft': '10px' }} name="question"
                        placeholder="Enter Question Here"
                        rows={question.type != 'heading' ? 2 : 4}
                        onChange={(e) => { onChange(e, 'questionText', question) }}
                        value={question.questionText} autoResize={true} />

                    {question.type != 'heading' && <> <div>
                        <span className="tab-view-label"
                            style={{ 'fontWeight': 'bold', 'fontSize': '14px', 'marginBottom': '3px' }}>Question Description</span>
                        {/* {error.question && <span className="error-message"> {error.question}</span>} */}
                    </div>
                        <InputTextarea style={{ 'fontSize': 'normal', 'marginLeft': '10px' }} name="question"
                            placeholder="Enter Question Description Here"
                            rows={1}
                            onChange={(e) => { onChange(e, 'questionDescription', question) }}
                            value={question.questionDescription} autoResize={true} />
                    </>
                    }


                    {question.type != 'heading' && <div className="">
                        <div className="col-12">
                            <div className="row" style={{
                                'display': 'flex',
                                'flexDirection': 'column', 'marginTop': '5px'
                            }}>
                                <div className="row" style={{
                                    'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between'
                                    , 'maxWidth': '590px'
                                }}>
                                    <div>
                                        <span className="tab-view-label" style={{ 'fontWeight': 'bold', 'fontSize': '14px', 'marginBottom': '3px' }}>Option<span style={{ fontWeight: 600, color: '#b21dac', paddingLeft: 3 }}>*</span> : </span>
                                        {error.option && <span className="error-message"> {error.option}</span>}
                                    </div>

                                </div>

                                <Options key={'question' + question.tempId} optionWithImage={question.optionWithImage} {...props} />
                            </div>
                        </div>
                    </div>}

                </div>
            </div>
        }
    </div>)
}
const Options = (props) => {
    const { question, onChange } = props;
    // return optionsObject[question.typeId];
    const disableActions = question.yesOrNo === true;
    switch (question.type) {
        case 'numeric':
            return <NumericOptions options={question.choices} {...props} />;
        case 'openText':
            return <OpenTextOptions options={question.choices} {...props} />;
        case 'radiogroup':
            return <MultipleListOptions options={question.choices} {...props} disableActions={disableActions} />;
        case 'checkbox':
            return <MultipleListOptions options={question.choices} {...props} disableActions={disableActions} />;
        case 'NPS':
            return <NPSOptions options={question.choices} {...props} />;
        case 'date':
            return <DateOptions options={question.choices} {...props} />;
        case 'starRating':
            return <StarRankOptions options={question.choices} {...props} />;
        case 'rating':
            return <EmojiRankOptions options={question.choices} {...props} />;
        case 'selectList':
            return <SelectOptions options={question.choices} {...props} />;
        case 'ranking':
            return <SelectOptions options={question.choices} {...props} />;
        case 'singleDropdown':
            return <MultipleListOptions options={question.choices} {...props} disableActions={disableActions} />;
        case 'multiselectdropdown':
            return <MultipleListOptions options={question.choices} {...props} disableActions={disableActions} />;
        case 'boolean':
            return <BooleanQuestion options={question.choices} {...props} disableActions={disableActions} />;
        case 'sliderScale':
            return <SliderQuestion options={question.choices} {...props} />;
        case 'matrix':
            return <MatrixQuestion options={question.choices} {...props} disableActions={disableActions} />;
        case 'matrixmultiChoice':
            return <MatrixQuestion options={question.choices} {...props} disableActions={disableActions} />;
        case 'imagepicker':
            return <ImageSelectionQuestion options={question.choices} {...props} disableActions={disableActions} />;

        default:
            return <></>;
    }
}

const SelectOptions = (props) => {
    const { question, options, onChange } = props;
    const optionsRaw = options.join(',');
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (optionsRaw && optionsRaw.length > 0) {
            setValue(options.length);
        }
        return () => { }
    }, [optionsRaw])

    const getUpdatedOptions = (value) => {
        return value.replaceAll('\r\n', '\n').replaceAll('\n', ',\n').split(',').reduce((a, b) => b.trim() !== "" ? a === '' ? b.trim() : a + ',\n' + b.trim() : a, '');
    }

    const change = (e) => {
        if (e && e.target) {
            e.target.index = 0;
            onChange(e, 'selectListOptions', question, true, 'value')
        }
    }

    const changePaste = (e) => {
        if (e && e.target) {
            e.target.index = 0;
            let paste = (e.clipboardData || {}).getData('text');
            paste = "\r\n" + paste;
            let data_comma = [];
            let splitByComma = paste.split(',');

            splitByComma.map((stringSeperated, index) => {
                if (!stringSeperated.includes("\n")) {
                    stringSeperated = "\r\n" + stringSeperated;
                    splitByComma[index] = stringSeperated
                }
                else {
                    splitByComma[index] = stringSeperated
                }
            });

            paste = splitByComma.join(',');
            let data = [];
            let child = paste.split('\n');
            child.map((element, index) => {
                child[index] = element.trim();
            });
            if (child[1].includes(',')) {
                child[child.length - 1] = child[child.length - 1] + ',';
            }

            let dataCheck = child && child.filter((item, index) => child.indexOf(item) !== index)
            if (dataCheck.length >= 1) {
                // dispatch(loadToaster({ type: 'info', title: 'Removed Duplicates', msg: `Removed ${dataCheck.length} Duplicate brand items from the pasted items!` }))
            }
            paste = Array.from(new Set(child)).join('\n');
            e.target.value = getUpdatedOptions(e.target.value + paste);
            onChange(e, 'selectListOptions', question, true, 'value');
            e.preventDefault();
        }
    }

    return (
        <>
            <div className="row mx-0">
                <div style={{ width: '75%' }}>
                    <InputTextarea onChange={change}
                        onPaste={changePaste} value={optionsRaw}
                        style={{ width: '100%' }} rows={11} autoResize={false} placeholder=" Paste list of options delimited by ',' "></InputTextarea>
                </div>
                <div>
                    <span className="label ml-3" style={{ 'fontWeight': 'bold', 'fontSize': '14px' }}>Options Count :
                        {optionsRaw
                            ? optionsRaw.split(',').filter(function (s) {
                                return s.trim() !== '';
                            }).length
                            : 0}</span>
                </div>
            </div>
        </>
    )
}


const NPSOptions = (props) => {
    const { question, options, onChange } = props;
    const [value, setValue] = useState(undefined);
    const change = (val) => {
        // if (onChange)
        //     onChange(parseInt(val), 'relatedAnswer', question, true);
    }
    return (
        <>
            <div className="option" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                {
                    npsData.map((element) => (
                        <CustomButton
                            key={element.value}
                            name={'nps' + element.value}
                            label={element.value}
                            className={element.className}
                            style={{ height: '40px', minWidth: '40px', margin: '0 5px' }}
                            onClick={(e) => { change(element.value) }}
                        />
                    ))
                }
            </div>

        </>
    )
}

const npsData = [
    { value: '0', className: 'p-button-danger margin-right' },
    { value: '1', className: 'p-button-danger margin-right' },
    { value: '2', className: 'p-button-danger margin-right' },
    { value: '3', className: 'p-button-danger margin-right' },
    { value: '4', className: 'p-button-danger margin-right' },
    { value: '5', className: 'p-button-danger margin-right' },
    { value: '6', className: 'p-button-danger margin-right' },
    { value: '7', className: 'p-button-warning margin-right' },
    { value: '8', className: 'p-button-warning margin-right' },
    { value: '9', className: 'p-button-success margin-right' },
    { value: '10', className: 'p-button-success margin-right' }
];

const MultipleListOptions = (props) => {
    const { question, options, onChange, optionWithImage, addOption, removeOption, disableActions } = props;
    return (
        <div>
            {options && options.length > 0 && <>
                <div className="option" key={options[0].tempId + "option" + 0}>


                    <InputTextarea
                        style={{
                            'fontSize': 'normal',
                            'minWidth': question.codedOption ? '55%' : '75%',
                            maxWidth: 'fit-content',
                            marginRight: '5px',
                            height: '40px'

                        }} name={"option" + 0}
                        placeholder="Enter option text/value"
                        onChange={(e) => { e.target.index = 0; onChange(e, 'options', question, true, 'text') }}
                        value={options[0].text}
                        disabled={disableActions === true}
                        rows={1}
                        autoResize={true}
                    />
                    {question.codedOption ? <InputTextarea
                        style={{
                            height: '40px',
                            'fontSize': 'normal',
                            'minWidth': question.codedOption ? '15%' : '75%',
                            maxWidth: 'fit-content', marginRight: '5px'
                        }}
                        name={"option" + 0} placeholder="Enter Coded Value"
                        onChange={(e) => {
                            e.target.index = 0;
                            onChange(e, 'options', question, true, 'value')
                        }}
                        rows={1}
                        autoResize={true}
                        value={options[0].value} disabled={disableActions === true} />
                        : null}
                    {disableActions === false && <RawOptionsPaste {...props} />}

                </div>
                {
                    options.map((option, index) => {
                        if (index === 0)
                            return <></>
                        else
                            return (
                                <div className="option" key={option.tempId + "option" + (index)}>


                                    <InputTextarea
                                        rows={1}
                                        autoResize={true}
                                        style={{
                                            'fontSize': 'normal',
                                            'minWidth': question.codedOption ? '55%' : '75%',
                                            maxWidth: 'fit-content',
                                            marginRight: '5px',
                                            height: '40px'
                                        }}
                                        name={"option" + index}
                                        placeholder="Enter option text/value"
                                        onChange={(e) => { e.target.index = index; onChange(e, 'options', question, true, 'text') }}
                                        value={option.text}
                                        disabled={!isNullorEmpty(option.optionType)}
                                    />

                                    {question.codedOption ? <InputTextarea
                                        rows={1}
                                        autoResize={true}
                                        style={{
                                            height: '40px',
                                            'fontSize': 'normal',
                                            'minWidth': question.codedOption ? '15%' : '75%',
                                            maxWidth: 'fit-content', marginRight: '5px'
                                        }} name={"option" + index} placeholder="Enter Coded Value"
                                        onChange={(e) => { e.target.index = index; onChange(e, 'options', question, true, 'value') }}
                                        value={option.value} disabled={disableActions === true || !isNullorEmpty(option.optionType)} />
                                        : null}
                                    {(disableActions === false && isNullorEmpty(option.optionType)) && <CustomButton
                                        name="AddOption"
                                        label=""
                                        className="custom-primary-button margin-right"
                                        icon="pi pi-plus"
                                        style={{
                                            height: '30px',
                                            lineHeight: '1.5',
                                            padding: '0 0.5rem',
                                            fontSize: '1rem'
                                        }}
                                        onClick={(e) => { addOption(index + 1) }}
                                    />}
                                    {options.length > 2 &&
                                        (disableActions === false && isNullorEmpty(option.optionType))
                                        && <CustomButton
                                            name="RemoveOption"
                                            label=""
                                            className="p-button-danger margin-right"
                                            icon="pi pi-times"
                                            style={{
                                                backgroundColor: '#7c38bc',
                                                borderColor: '#7c38bc',
                                                height: '30px',
                                                lineHeight: '1.5',
                                                padding: '0 0.5rem',
                                                fontSize: '1rem'
                                            }}
                                            onClick={(e) => { removeOption(index) }}
                                        />}

                                    {/* {question.typeId === 12 ?
                                        <>

                                            <InputSwitch checked={question.choices[index].isQualifiedAnswer}
                                                // onChange={(e) => onChange(e, 'isQualifiedAnswer', question)} 
                                                onChange={(e) => { e.target.index = index; onChange(e, 'isQualifiedAnswer', question) }}
                                            /> </> : null} */}


                                </div>
                            )
                    })
                }

            </>}
        </div>
    )

}

const ImageSelectionQuestion = (props) => {
    const { question, options, onChange, optionWithImage, addOption, removeOption, disableActions } = props;

    const updateImage = (image, index) => {
        const obj = { target: { value: image, index: index } }
        onChange(obj, 'imageOptions', question, true, 'imageLink');
    }
    return (
        <div>
            {options && options.length > 0 && <>
                <div className="option" key={options[0].tempId + "option" + 0}>
                    {((options[0].imageLink || '').trim() === "" ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="image-close-duplicate"></div>
                            <CompressedImageUpload
                                name="imagePicker"
                                type="file"
                                onChange={(e) => updateImage(e, 0)}
                            />
                        </div> :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DisplayIcon className="margin-right"
                                imageData={options[0].imageLink} alt={options[0].imageLink} />
                            <CustomButton style={{
                                marginLeft: '5px',
                            }}
                                name="addImages"
                                label="Image"
                                className="p-button-primary margin-right"
                                icon="pi pi-minus"
                                onClick={() => updateImage('', 0)}
                            />
                        </div>
                    )}

                    <input
                        style={{
                            'fontSize': 'normal',
                            'minWidth': '35%',
                            maxWidth: 'fit-content',
                            marginLeft: '5px',
                            marginRight: '5px',
                            height: '40px'

                        }} name={"option" + 0}
                        placeholder="Enter option text/value"
                        onChange={(e) => { e.target.index = 0; onChange(e, 'imageOptions', question, true, 'value') }}
                        value={options[0].value}
                        disabled={disableActions === true}
                    />


                </div>
                {
                    options.map((option, index) => {
                        if (index === 0)
                            return <></>
                        else
                            return (
                                <div className="option" key={option.tempId + "option" + (index)}>
                                    {((option.imageLink || '').trim() === "" ?
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="image-close-duplicate"></div>
                                            <CompressedImageUpload
                                                name="voucherImage"
                                                type="file"
                                                onChange={(e) => updateImage(e, index)}
                                            />
                                        </div> :
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <DisplayIcon className="margin-right" imageData={option.imageLink} alt={option.value} />
                                            {/* <div className="image-close" onClick={(e: any) => updateImage('', index)}><span className="icon">x</span></div> */}
                                            <CustomButton style={{
                                                marginLeft: '5px',
                                            }}
                                                name="addImages"
                                                label="Image"
                                                className="p-button-primary margin-right"
                                                icon="pi pi-minus"
                                                onClick={() => updateImage('', index)}
                                            />
                                        </div>
                                    )}

                                    <input
                                        style={{
                                            'fontSize': 'normal',
                                            'minWidth': '35%',
                                            maxWidth: 'fit-content',
                                            marginLeft: '5px',
                                            marginRight: '5px',
                                            height: '40px'
                                        }}
                                        name={"option" + index}
                                        placeholder="Enter option text/value"
                                        onChange={(e) => { e.target.index = index; onChange(e, 'imageOptions', question, true, 'value') }}
                                        value={option.value}
                                        disabled={!isNullorEmpty(option.optionType)}
                                    />


                                    {(disableActions === false && isNullorEmpty(option.optionType)) && <CustomButton
                                        name="AddOption"
                                        label=""
                                        className="custom-primary-button margin-right"
                                        icon="pi pi-plus"
                                        style={{
                                            height: '30px',
                                            lineHeight: '1.5',
                                            padding: '0 0.5rem',
                                            fontSize: '1rem'
                                        }}
                                        onClick={(e) => { addOption(index + 1) }}
                                    />}
                                    {options.length > 2 &&
                                        (disableActions === false && isNullorEmpty(option.optionType))
                                        && <CustomButton
                                            name="RemoveOption"
                                            label=""
                                            className="p-button-danger margin-right"
                                            icon="pi pi-times"
                                            style={{
                                                backgroundColor: '#7c38bc',
                                                borderColor: '#7c38bc',
                                                height: '30px',
                                                lineHeight: '1.5',
                                                padding: '0 0.5rem',
                                                fontSize: '1rem'
                                            }}
                                            onClick={(e) => { removeOption(index) }}
                                        />}

                                    {/* {question.typeId === 12 ?
                                        <>

                                            <InputSwitch checked={question.choices[index].isQualifiedAnswer}
                                                // onChange={(e) => onChange(e, 'isQualifiedAnswer', question)} 
                                                onChange={(e) => { e.target.index = index; onChange(e, 'isQualifiedAnswer', question) }}
                                            /> </> : null} */}


                                </div>
                            )
                    })
                }

            </>}
        </div>
    )


}

const MatrixQuestion = (props) => {
    const { question, options, onChange, optionWithImage, addOption, matrixAddOption, matrixRemoveOption, removeOption, disableActions } = props;
    return (
        <div className='p-inputtextarea'>
            <div>
                <div style={{ fontSize: '16px', fontFamily: 'bold' }}>Row Options :</div>
                <>
                    <div className="option" key={question.rows[0].tempId + "option" + 0}>


                        <input
                            style={{
                                'fontSize': 'normal',
                                'minWidth': '75%',
                                maxWidth: 'fit-content',
                                marginRight: '5px',
                                height: '40px'

                            }} name={"option" + 0}
                            placeholder="Enter option text/value"
                            onChange={(e) => {
                                let newRows = [...question.rows];
                                newRows[0] = e.target.value;
                                onChange(e, 'rows', { ...question, rows: newRows });
                            }}
                            value={question.rows[0]}
                            disabled={disableActions === true}
                            className="p-inputtext"
                        />


                    </div>

                    {question.rows.map((row, index) => {
                        if (index === 0)
                            return <></>
                        else
                            return (
                                <div className="option" key={index}>
                                    <input
                                        style={{
                                            'fontSize': 'normal',
                                            'minWidth': '75%',
                                            maxWidth: 'fit-content',
                                            marginRight: '5px',
                                            height: '40px'
                                        }}
                                        name={"option" + index}
                                        placeholder="Enter option text/value"
                                        disabled={disableActions === true}
                                        value={row}
                                        onChange={(e) => {
                                            let newRows = [...question.rows];
                                            newRows[index] = e.target.value;
                                            onChange(e, 'rows', { ...question, rows: newRows });
                                        }}
                                        className="p-inputtext"
                                    />
                                    <CustomButton
                                        name="AddOption"
                                        label=""
                                        className="custom-primary-button margin-right"
                                        icon="pi pi-plus"
                                        style={{
                                            height: '30px',
                                            lineHeight: '1.5',
                                            padding: '0 0.5rem',
                                            fontSize: '1rem'
                                        }}
                                        onClick={(e) => { matrixAddOption(index + 1, "rows") }}
                                    />

                                    {question.rows.length > 2 && disableActions === false && <CustomButton
                                        name="RemoveOption"
                                        label=""
                                        className="p-button-danger margin-right"
                                        icon="pi pi-times"
                                        style={{
                                            backgroundColor: '#7c38bc',
                                            borderColor: '#7c38bc',
                                            height: '30px',
                                            lineHeight: '1.5',
                                            padding: '0 0.5rem',
                                            fontSize: '1rem'
                                        }}
                                        onClick={(e) => { matrixRemoveOption(index, "rows") }}
                                    />}
                                </div>
                            )
                    })}

                </>
            </div>
            <div>
                <div style={{ fontSize: '16px', fontFamily: 'bold', marginTop: '5px' }}>Column Options :</div>
                <>
                    <div className="option" key={question.columns[0].tempId + "option" + 0}>


                        <input
                            style={{
                                'fontSize': 'normal',
                                'minWidth': '75%',
                                maxWidth: 'fit-content',
                                marginRight: '5px',
                                height: '40px'

                            }} name={"option" + 0}
                            placeholder="Enter option text/value"
                            onChange={(e) => {
                                let newRows = [...question.columns];
                                newRows[0] = e.target.value;
                                onChange(e, 'columns', { ...question, columns: newRows });
                            }}
                            value={question.columns[0]}
                            disabled={disableActions === true}
                            className="p-inputtext"
                        />

                    </div>

                    {question.columns.map((row, index) => {
                        if (index === 0)
                            return <></>
                        else
                            return (
                                <div className="option" key={index}>
                                    <input
                                        style={{
                                            'fontSize': 'normal',
                                            'minWidth': '75%',
                                            maxWidth: 'fit-content',
                                            marginRight: '5px',
                                            height: '40px'
                                        }}
                                        name={"option" + index}
                                        placeholder="Enter option text/value"
                                        disabled={disableActions === true}
                                        value={row}
                                        onChange={(e) => {
                                            let newRows = [...question.columns];
                                            newRows[index] = e.target.value;
                                            onChange(e, 'columns', { ...question, columns: newRows });
                                        }}
                                        className="p-inputtext"
                                    />
                                    <CustomButton
                                        name="AddOption"
                                        label=""
                                        className="custom-primary-button margin-right"
                                        icon="pi pi-plus"
                                        style={{
                                            height: '30px',
                                            lineHeight: '1.5',
                                            padding: '0 0.5rem',
                                            fontSize: '1rem'
                                        }}
                                        onClick={(e) => { matrixAddOption(index + 1, "columns") }}
                                    />

                                    {question.columns.length > 2 && disableActions === false && <CustomButton
                                        name="RemoveOption"
                                        label=""
                                        className="p-button-danger margin-right"
                                        icon="pi pi-times"
                                        style={{
                                            backgroundColor: '#7c38bc',
                                            borderColor: '#7c38bc',
                                            height: '30px',
                                            lineHeight: '1.5',
                                            padding: '0 0.5rem',
                                            fontSize: '1rem'
                                        }}
                                        onClick={(e) => { matrixRemoveOption(index, "columns") }}
                                    />}
                                </div>
                            )
                    })}

                </>
            </div>
        </div>
    )

}




const BooleanQuestion = (props) => {
    const { question, options, onChange, optionWithImage, addOption, removeOption, disableActions } = props;
    return (
        <div className='p-inputtextarea'>
            <>
                <div className="option">

                    <input
                        style={{
                            'fontSize': 'normal',
                            'minWidth': '75%',
                            maxWidth: 'fit-content',
                            marginRight: '5px',
                            height: '40px'

                        }} name={"option" + 0}
                        placeholder="Enter option text/value"
                        onChange={(e) => { e.target.index = 0; onChange(e, 'booleanYesOptions', question, true, 'value') }}
                        value={question.labelTrue}
                        className="p-inputtext"

                    />


                </div>
                <div className="option">
                    <input
                        style={{
                            'fontSize': 'normal',
                            'minWidth': '75%',
                            maxWidth: 'fit-content',
                            marginRight: '5px',
                            height: '40px'

                        }} name={"option" + 0}
                        placeholder="Enter option text/value"
                        onChange={(e) => { e.target.index = 0; onChange(e, 'booleanNoOptions', question, true, 'value') }}
                        value={question.labelFalse}
                        className="p-inputtext"

                    />


                </div>

            </>
        </div>
    )

}

const DateOptions = (props) => {
    const { question, options, onChange } = props;

    const [value, setValue] = useState(undefined);
    // value !== null ? moment(value.toString()).format("YYYY-MM-DD") : ''
    return (
        <>

            <div className="col-5" style={{ padding: 0 }}>

                <div className='custom-calender'>
                    <Calendar
                        value={value}
                        onChange={(e) => onChange(e, 'dateFormat', question)}
                        placeholder="Example Date"
                        name="exampleDate"
                        showIcon={true}
                        showButtonBar={true}
                    />
                </div>
            </div>
        </>
    )
}

const StarRankOptions = (props) => {
    const { question, options, onChange } = props;
    const [value, setValue] = useState(undefined);

    const handleRatingChange = (value) => {
        setValue(value);
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= question.rateMax; i++) {
            stars.push(
                <Button
                    key={i}
                    variant="light"
                    className="mx-1"
                    onClick={() => handleRatingChange(i)}

                >
                    <i
                        className={`bi bi-star${value >= i ? "-fill" : ""} text-warning`}
                    ></i>
                </Button>
            );
        }
        return stars;
    };
    return (
        <>
            <div className="d-flex align-items-center">{renderStars()}</div>
        </>
    )
}


const EmojiRankOptions = (props) => {
    const { question, options, onChange } = props;
    const [value, setValue] = useState(undefined);

    const handleRatingChange = (value) => {
        setValue(value);
    };
    const smileyList = ["😞", "😕", "😐", "😊", "😄", "😃", "🤩"];

    const renderSmileys = () => {
        const smileys = [];
        for (let i = 1; i <= question.rateMax; i++) {
            const index = Math.floor((i - 1) / (question.rateMax / smileyList.length));
            const isSelected = i === value;
            smileys.push(
                <Button
                    key={i}
                    variant="light"
                    className={`mx-1 ${isSelected ? 'bg-primary text-white' : ''}`}
                    onClick={() => handleRatingChange(i)}
                >
                    {smileyList[index]}
                </Button>
            );
        }
        return smileys;
    };


    return (
        <>
            <div className="d-flex align-items-center">{renderSmileys()}</div>
        </>
    )
}

const OpenTextOptions = (props) => {
    const { question, options, onChange } = props;

    return (
        <>
            <div style={{ width: '75%' }}>
                <InputTextarea style={{ width: '100%' }} rows={1} cols={30} autoResize={true} value="Sample Text" disabled></InputTextarea>
            </div>
        </>
    )
}

const NumericOptions = (props) => {
    const { question, options, onChange } = props;
    return (
        <div className='p-inputtextarea'>
            <div style={{ width: '75%' }}>
                <input name="numeric" style={{ width: '100%' }} value="0" disabled
                    className="p-inputtext"
                />
            </div>
        </div>
    )
}

const RawOptionsPaste = (props) => {
    const { question, options, onChange, addMultipleOptions } = props;
    const [optionsRaw, setOptionsRaw] = useState('');
    const [value, setValue] = useState(0);
    const [data, setDuplicate] = useState([]);
    const [showCopy, setShowCopy] = useState(false);

    // useEffect(() => {
    //     if (options) {
    //         setOptionsRaw(options.map((o ) => o.value).join('\n'));
    //     }
    //     return () => { }
    // }, [options])

    useEffect(() => {
        if (optionsRaw && optionsRaw.length > 0) {
            setValue(optionsRaw.split('\n').filter((a) => !isNullorEmpty(a)).length)
        }
        return () => { }
    }, [optionsRaw])

    const getUpdatedOptions = (value) => {
        return value.split('\n').reduce((a, b) => b.trim() !== "" ? a === '' ? b.trim() : a + '\n' + b.trim() : a, '');
    }

    const change = (e) => {
        if (e && e.target) {
            e.target.index = 0;
            // onChange(e, 'options', question, true, 'value')
            setOptionsRaw(e.target.value);
        }
    }

    const changePaste = (e) => {
        if (e && e.target) {
            e.target.index = 0;
            let paste = (e.clipboardData || {}).getData('text');
            // paste = (paste);
            e.target.value = getUpdatedOptions(e.target.value + paste);
            // onChange(e, 'options', question, true, 'value');
            setOptionsRaw(e.target.value);
            e.preventDefault();
        }
    }

    const updateOptions = () => {
        if (addMultipleOptions)
            addMultipleOptions(optionsRaw.split('\n').filter((o) => o.trim() !== '').map((o) => ({ value: o, text: o })));
        setShowCopy(false);
        setOptionsRaw('');
    }

    const renderFooter = () => {
        return (
            <div>
                <CustomButton name='yes' label="Add Options" icon="pi pi-check" onClick={updateOptions} />
                <CustomButton name='no' label="Cancel" icon="pi pi-times" onClick={hide} className="p-button-secondary" />
            </div>
        );
    }

    const hide = () => {
        setShowCopy(false)
        setOptionsRaw('');
    }

    return (
        <>
            <Dialog header={"Paste Options"} visible={showCopy}
                style={{ width: '40vw', 'overflow': 'none' }}
                onHide={hide} maximizable={false} footer={renderFooter()}>
                <div className="row mx-0">
                    <div style={{ width: '100%' }}>
                        <InputTextarea onChange={change} onPaste={changePaste} value={optionsRaw} style={{ width: '100%' }} rows={11} autoResize={false} placeholder=" Paste list of options delimited by new line "></InputTextarea>
                    </div>
                    <div>
                        <span className="label ml-3" style={{ 'fontWeight': 'bold', 'fontSize': '14px' }}>Options Count : {optionsRaw ? value : 0}</span>
                    </div>
                </div>
            </Dialog>
            <div>
                <CustomButton name='paste' label="" className="p-button-warning margin-right"
                    icon="fa fa-clipboard fa-lg paste-clipboard"
                    onClick={() => setShowCopy(true)} tooltip={"paste in clipboard"}
                    style={{
                        height: '30px',
                        lineHeight: '1.5',
                        padding: '0 0.5rem',
                        fontSize: '1rem'
                    }}
                />
            </div>
        </>
    )
}

const SliderQuestion = (props) => {
    const { question, options, onChange } = props;
    const [value, setValue] = useState(question.minValue);

    const handleInputChange = (event) => {
        const newValue = parseInt(event.target.value);
        setValue(newValue);
    };
    const sliderStyle = {
        width: "100%",
        background:
            "linear-gradient(to right, #87CEFA, #87CEFA " +
            ((value - question.minValue) / (question.maxValue - question.minValue)) * 100 +
            "%, #f5f5f5 " +
            ((value - question.minValue) / (question.maxValue - question.minValue)) * 100 +
            "%, #f5f5f5)",
    };

    const handleDecreaseClick = () => {
        const newValue = value - question.step;
        if (newValue >= question.minValue) {
            setValue(newValue);
        }
    };

    const handleIncreaseClick = () => {
        const newValue = value + question.step;
        if (newValue <= question.maxValue) {
            setValue(newValue);
        }
    };
    return (
        <>
            <div style={{ width: '100%' }}>
                {/* <div className="mx-2">{value}</div>
                <input
                    type="range"
                    name={question.name}
                    id={question.name}
                    min={question.minValue}
                    max={question.maxValue}
                    step={question.step}
                    value={value}
                    onChange={handleInputChange}
                    className="form-control-range"
                    style={sliderStyle}
                // disabled
                /> */}

                <div className="d-flex align-items-center">
                    <div className="col-1">
                        <Button variant="outline-secondary" onClick={handleDecreaseClick}>
                            -
                        </Button>
                    </div>
                    <div className="justify-content-center mb-3 align-items-center col-10">
                        <div className="text-center">
                            <div className="mx-2">{value}</div>
                            <input
                                type="range"
                                name={question.name}
                                id={question.name}
                                min={question.minValue}
                                max={question.maxValue}
                                step={question.step}
                                value={value}
                                onChange={handleInputChange}
                                className="form-control-range"
                                style={sliderStyle}
                            />
                        </div>
                    </div>

                    <div className="col-1">
                        <Button variant="outline-secondary" onClick={handleIncreaseClick}>
                            +
                        </Button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Content