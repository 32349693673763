import axios from "axios";
import { API_END_POINT } from "../../../../config";
import { startSpinner, stopSpinner } from "../../../shared-components/custom-spinner/custom-spinner-store/spinner-actions";
import { fetchCurrentSurveyID, fetchProjectList, TestAction } from "./home-actions-creators";
import { loadToaster } from "../../../shared-components/toaster/toaster-actions-creators";

export const fetchTestCall = () => {
    return (dispatch) => {
        try {
            // dispatch(startSpinner());
            dispatch(TestAction("ravikrishnapunnam@gmail.com"));

        } catch (err) {

        }

    };
};
export const fetchSurveyDetails = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await axios.get(`${API_END_POINT.BASE_URL}/api/Survey/GetSurveyAll/${postObject}`);
            let getDetails = response.data.data;
            const modifiedSurveys = getDetails.surveys.map(survey => {
                const endDate = survey.end_date.split("T")[0];
                const startDate = survey.start_date.split("T")[0];
                let statusText = "";
                switch (survey.survey_status_id) {
                    case 1:
                        statusText = "Initiated";
                        break;
                    case 2:
                        statusText = "InProgress";
                        break;
                    case 3:
                        statusText = "Completed";
                        break;
                    default:
                        statusText = "Unknown";
                        break;
                }
                return { ...survey, start_date: startDate, end_date: endDate, survey_status_id: statusText };
            });
            dispatch(fetchProjectList(modifiedSurveys));
            dispatch(stopSpinner());

        } catch (err) {
            console.error(err);
            dispatch(stopSpinner());
            throw err; // Throw the error to the caller
        }
    };
};

export const CloneSurvey = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await axios.post(`${API_END_POINT.BASE_URL}/api/Survey/PostSurveyClone`, postObject);
            dispatch(fetchSurveyDetails(postObject.created_by));
            dispatch(loadToaster({ type: 'success', title: 'Cloned Successfully!!', msg: '' }))
            dispatch(stopSpinner());

        } catch (err) {
            console.error(err);
            dispatch(stopSpinner());
            throw err; // Throw the error to the caller
        }
    };
};