import React from 'react'
import "./Footer.css"
const Footer = () => {
  return (
    <div className='footer'>
     Version 0.0.1 @Test version as it is still in development stage.
      </div>
  )
}

export default Footer