import React from 'react';
import { Menubar } from 'primereact/menubar';
import { PrimeIcons } from 'primereact/api';
import './TopBar.css';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Topbar = () => {
  const history = useHistory();
  const { currentSurveyID } = useSelector((state) => state.projectList);
  console.log(currentSurveyID, "currentSurveyID")
  const items = [
    {
      label: 'Home',
      icon: PrimeIcons.HOME,
      command: () => history.push('/surveys/survey-lists'),
    },
    {
      label: 'Dashboard',
      icon: 'fa fa-tachometer',
      command: () => history.push(`/surveys/edit-survey/${currentSurveyID}/dashboard`),
      disabled: currentSurveyID === null,
    },
    {
      label: 'Details',
      icon: PrimeIcons.LIST,
      command: () => {
        if (currentSurveyID === null) {
          history.push('/surveys/create-survey');
        } else {
          history.push(`/surveys/edit-survey/${currentSurveyID}`);
        }
      },
      disabled: false,
    },
    {
      label: 'Design',
      icon: 'fa-regular fa-pencil',
      command: () => history.push(`/surveys/edit-survey/${currentSurveyID}/design`),
      disabled: currentSurveyID === null,
    },
    {
      label: 'Respondents',
      icon: PrimeIcons.USERS,
      command: () => history.push(`/surveys/edit-survey/${currentSurveyID}/respondents`),
      disabled: currentSurveyID === null,
    },
    {
      label: 'Invitation',
      icon: PrimeIcons.ENVELOPE,
      command: () => history.push(`/surveys/edit-survey/${currentSurveyID}/invitation`),
      disabled: currentSurveyID === null,
    },
    {
      label: 'Data',
      icon: PrimeIcons.DATABASE,
      command: () => history.push(`/surveys/edit-survey/${currentSurveyID}/data`),
      disabled: currentSurveyID === null,
    },
    {
      label: 'Documentation',
      icon: 'fa-thin fa-bookmark',
      command: () => history.push(`/surveys/edit-survey/${currentSurveyID}/documentation`),
      disabled: currentSurveyID === null,
    },
  ];



  return (
    <div className="topbar">
      <Menubar model={items} />
    </div>
  );
};

export default Topbar;
