import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import CustomSpinner from './components/shared-components/custom-spinner/custom-spinner';
import ToastComponent from './components/shared-components/toaster/toaster';
import AppRoutes from './AppRoutes';
import PageRefreshDialog from './components/HomeNew/PageRefresh/PageRefresh';

function App() {
  return (
    <Provider store={store}>
      <CustomSpinner />
      <ToastComponent />
      <PageRefreshDialog/>
      <AppRoutes />
    </Provider>
  );
}

export default App;
