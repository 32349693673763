import React from 'react';
import "./Header.css";
import Dropdown from 'react-bootstrap/Dropdown';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Icon from '../../../Images/ps_logo.png';

const Header = ({ onLogout }) => {
  const history = useHistory();
  const { test, userLoginDetails } = useSelector((state) => state.login);
  // console.log(userLoginDetails, "Header");
  // const logout = () => {
  //   localStorage.clear();
  //   history.push('/login');

  // }
  return (
    <div className='header' style={{
      display: 'flex',
      justifyContent: 'space-between', alignItems: 'center', zIndex: '1000'
    }}>
      <div className="row">
        <div className="col">
          <img className="navbar-brand" src={Icon} alt="logo"
            style={{ objectFit: 'contain', width: "70%", marginLeft: '3%' }} />
        </div>
      </div>
      <Dropdown drop="down">
        <Dropdown.Toggle id="dropdown-basic">
          <i className="pi pi-user"></i> <span className="username">{userLoginDetails.user_name}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>
            <i className="fa fa-sign-out"></i>
            <span onClick={onLogout}>Log Out</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Header;
