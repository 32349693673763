import { TEST, POST_SURVEY_DETAIL_BY_ID, FETCH_SURVEY_DETAIL_BY_ID } from "./details-store-constants";

export function TestAction(data) {
    return {
        type: TEST,
        payload: {
            test: data
        }
    }
}
export function getSurveyDetailsByID(data) {
    return {
        type: FETCH_SURVEY_DETAIL_BY_ID,
        payload: {
            getSurveyByID: data
        }
    }
}