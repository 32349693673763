import React, { useState, useEffect, useCallback } from 'react'
import './design.css';
import { QuestionsList, QuestionTypeList } from './QuestionsList';
import { TabView, TabPanel } from 'primereact/tabview';
import Content from './Content';
import Settings from './Settings';
import Button from '../../shared-components/Button/custom-button';
import DetailsContent from './HeaderDesign/DetailsContent';
import SettingsContent from './HeaderDesign/SettingsContent';
import FooterSettings from './FooterDesign/FooterSettings';
import { Dialog } from "primereact/dialog";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DisplayLogic from './DisplayLogic';
import { useDispatch, useSelector } from 'react-redux';
import { createOrUpdateSurveyDefinition, fetchSurveyDefintionListByID } from './design-store/design-actions';
import { useHistory, useParams } from 'react-router-dom';
import { fetchCurrentSurveyID } from '../ProjectList/home-store/home-actions-creators';
import PageRefreshDialog from '../PageRefresh/PageRefresh';
import { fetchSurveyDetailsByID } from '../Details/details-store/details-actions';
import { validateAllQuestions, validateFooter, validateHeader, validateQuestionsAndOptions } from './Helpers/Validations';
import { loadToaster } from '../../shared-components/toaster/toaster-actions-creators';
import { Checkbox } from 'primereact/checkbox';

const Design = () => {
    const [activeEditTab, setActiveEditTab] = useState(0);
    const [activeHeaderEditTab, setActiveHeaderEditTab] = useState(0);
    const [activeQuestion, setActiveQuestion] = useState(-1);
    const [changes, setChanges] = useState(0);
    const [questionListSize, setQuestionListSize] = useState(0);
    const [random, setRandom] = useState(1);
    const [randomOptionId, setRandomOptionId] = useState(1);
    const [activeHeaderFooter, setActiveHeaderFooter] = useState('header');
    const [currentPage, setCurrentPage] = useState(0);
    const SAVED = 'SAVED';
    const DELETED = 'DELETED';
    const ADDED = 'ADDED';
    const UPDATED = 'UPDATED';
    const OTHER = 'OTHER';
    const NEVER = 'NEVER';
    const NONE = 'NONE';
    const ALONE = 'ALONE';
    const YES_NO = 'YES_NO';
    const dispatch = useDispatch();
    const { currentSurveyID, currentSurveyName } = useSelector((state) => state.projectList);
    const { userLoginDetails } = useSelector((state) => state.login);
    const { surveyDefinitionData } = useSelector((state) => state.designDetails);
    const { id } = useParams();
    const [error, setError] = useState({});
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [mergeQuestions, setMergeQuestions] = useState(false);

    const [tabHeaderFooterData, setTabHeaderFooterData] = useState({
        "header": {
            "title": "",
            "description": "",
            "logoPosition": "right",
            "titlePosition": "center",
            "logo": "",
            "surveyheadingForeground": {
                "margin": "auto",
                "color": "#ffffff",
                "font-size": "27px"
            },
            "surveyheadingBackground": {
                "background-color": "#FF0000",
                "padding": "10px"
            },
            "headerErrorStatus": true
        },
        "footer": {
            "previousbuttonlabel": "<<",
            "previousbuttonstyle": {
                "background-color": "#0000FF"
            },
            "nextbuttonlabel": ">>",
            "nextbuttonstyle": {
                "background-color": "#0000FF"
            },
            "progressbar": true,
            "progressbarstyle": {
                "background-color": "#FFFFFF"
            },
            "footerBackgroundcolor": "#000000",
            "footerErrorStatus": false
        },
        "pages": [
            {
                "elements": [

                ]
            }
        ]
    })

    useEffect(() => {
        if (id && userLoginDetails.user_id) {
            if (!currentSurveyID) {
                dispatch(fetchSurveyDetailsByID(`${id}/${userLoginDetails.user_id}`))
                    .then(() => {
                        dispatch(fetchSurveyDefintionListByID(`${id}/${userLoginDetails.user_id}`));
                    })
                    .catch((error) => {
                        // Handle error if fetchSurveyDetailsByID fails
                    });
            } else {
                dispatch(fetchSurveyDefintionListByID(`${id}/${userLoginDetails.user_id}`));
            }
        }
    }, [id, userLoginDetails.user_id]);


    useEffect(() => {
        if (Object.keys(surveyDefinitionData).length !== 0) {
            setTabHeaderFooterData(surveyDefinitionData);
        }
        return () => {
            console.log("going out of this component")
        }
    }, [surveyDefinitionData]);


    useEffect(() => {
        setActiveEditTab(0);
    }, [activeQuestion])


    useEffect(() => {
        setQuestionListSize(tabHeaderFooterData.pages[currentPage].elements.length);
        // adding this IF condition when data is already there,
        // get to know the length and it to the opt
        if (tabHeaderFooterData.pages[currentPage].elements.length > 0) {
            setRandom(tabHeaderFooterData.pages[currentPage].elements.length + 1);
        }
    }, [tabHeaderFooterData.pages[currentPage].elements])


    const getRandom = () => {
        const questionList = tabHeaderFooterData.pages.flatMap((page) => page.elements);

        let highestId = 0;
        if (questionList.length > 0) {
            highestId = questionList.reduce((maxId, question) => {
                if (question.id > maxId) {
                    return question.id;
                } else {
                    return maxId;
                }
            }, 0);
        }

        const key = highestId > 0 ? highestId + 1 : 1;

        setRandom(key + 1);
        return key;
    };


    const getRandomOptionId = () => {
        const key = randomOptionId;
        setRandomOptionId(key + 1);
        return key;
    }

    const addNewQuestion = (questionType) => {

        setChanges((a) => a + 1);
        let newQuestion = {};

        newQuestion.id = getRandom();
        newQuestion.tempId = 'Q' + (currentPage + 1) + '-' + getRandom();
        newQuestion.type = questionType.questionType;
        newQuestion.typeId = questionType.id;
        newQuestion.questionText = '';
        newQuestion.questionDescription = '';
        newQuestion.questionSequence = getRandom();
        newQuestion.rateMax = 5;
        newQuestion.minValue = 18;
        newQuestion.maxValue = 99;
        newQuestion.selectedType = questionType;
        let j = getRandomOptionId();
        if (newQuestion.type === 'selectList' || newQuestion.type === 'ranking') {
            newQuestion.choices = [];
        }
        else if (newQuestion.type === 'boolean') {
            newQuestion.labelTrue = 'Yes';
            newQuestion.labelFalse = 'No';

        }
        else if (newQuestion.type === 'matrix' || newQuestion.type === 'matrixmultiChoice') {
            newQuestion.rows = ["", ""];
            newQuestion.columns = ["", ""];
        }
        else if (newQuestion.type === 'imagepicker') {
            newQuestion.choices = [{ id: '', imageLink: '', value: '', tempId: j++ },
            { id: '', imageLink: '', value: '', tempId: j++ }];

        }
        else {
            newQuestion.choices = [{ id: '', text: '', value: '', tempId: j++ }, { id: '', text: '', value: '', tempId: j++ }];
        }
        setRandomOptionId(j);
        newQuestion.dateFormat = 'dd/MM/yyyy';
        newQuestion.isMandatory = false;
        newQuestion.optionWithImage = false;
        newQuestion.others = false;
        newQuestion.yesOrNo = false;
        newQuestion.choicesOrder = 'normal';
        newQuestion.codedOption = false;
        newQuestion.requiredErrorText = 'Answer cannot be empty'
        newQuestion.DLFormulaText = '';
        newQuestion.visibleIf = '';
        newQuestion.placeHolderText = 'Type Here..';
        newQuestion.status = ADDED;
        newQuestion.errorStatus = true;
        // newQuestion.projectId = projectId;
        setActiveQuestion(() => questionListSize);
        setActiveHeaderFooter(null);

        console.log(newQuestion, "New Question")

        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, index) => {
                if (index === currentPage) {
                    return {
                        ...page,
                        elements: [...page.elements, newQuestion]
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });


    }

    const matrixAddOption = (index, type) => {
        let newQuestion = JSON.parse(JSON.stringify(tabHeaderFooterData.pages[currentPage].elements[activeQuestion]));
        newQuestion[type].splice(index, 0, "");
        let newQuestionList = tabHeaderFooterData.pages[currentPage].elements.map((ques, idx) => {
            if (ques.tempId === newQuestion.tempId)
                return newQuestion;
            return ques;
        });

        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, index) => {
                if (index === currentPage) {
                    return {
                        ...page,
                        elements: newQuestionList
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });

    }
    const addOption = (index) => {
        setChanges((a) => a + 1);
        let newQuestion = JSON.parse(JSON.stringify(tabHeaderFooterData.pages[currentPage].elements[activeQuestion]));
        let options = JSON.parse(JSON.stringify(newQuestion.choices));
        const newOption = { id: '', text: '', value: '', tempId: getRandomOptionId() };
        options.splice(index, 0, newOption);
        newQuestion.choices = options;
        newQuestion.errorStatus = true;
        if (newQuestion.status !== ADDED)
            newQuestion.status = UPDATED;
        let newQuestionList = tabHeaderFooterData.pages[currentPage].elements.map((ques, idx) => {
            if (ques.tempId === newQuestion.tempId)
                return newQuestion;
            return ques;
        });

        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, index) => {
                if (index === currentPage) {
                    return {
                        ...page,
                        elements: newQuestionList
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });


    }
    const matrixRemoveOption = (index, type) => {
        setChanges((a) => a + 1);
        let newQuestion = JSON.parse(JSON.stringify(tabHeaderFooterData.pages[currentPage].elements[activeQuestion]));

        newQuestion[type].splice(index, 1);
        if (newQuestion.status !== ADDED)
            newQuestion.status = UPDATED;
        // const errors = validateQuestionsAndOptions(newQuestion);
        // setError(errors);
        // newQuestion.errorStatus = (errors && Object.keys(errors).length > 0) ? true : false;
        let newQuestionList = tabHeaderFooterData.pages[currentPage].elements.map((ques, idx) => {
            if (ques.tempId === newQuestion.tempId)
                return newQuestion;

            return ques;
        });
        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, index) => {
                if (index === currentPage) {
                    return {
                        ...page,
                        elements: newQuestionList
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });
    }

    const removeOption = (index) => {
        setChanges((a) => a + 1);
        let newQuestion = JSON.parse(JSON.stringify(tabHeaderFooterData.pages[currentPage].elements[activeQuestion]));
        let options = JSON.parse(JSON.stringify(newQuestion.choices));
        let optionValue = options[index].value;
        options.splice(index, 1);
        newQuestion.choices = options;
        if (newQuestion.status !== ADDED)
            newQuestion.status = UPDATED;
        const errors = validateQuestionsAndOptions(newQuestion);
        setError(errors);
        newQuestion.errorStatus = (errors && Object.keys(errors).length > 0) ? true : false;
        let newQuestionList = tabHeaderFooterData.pages[currentPage].elements.map((ques, idx) => {
            if (ques.tempId === newQuestion.tempId)
                return newQuestion;

            return ques;
        });
        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, index) => {
                if (index === currentPage) {
                    return {
                        ...page,
                        elements: newQuestionList
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });
    }

    const addMultipleOptions = (newOptions) => {
        setChanges((a) => a + 1);
        let newQuestion = JSON.parse(JSON.stringify(tabHeaderFooterData.pages[currentPage].elements[activeQuestion]));
        let options = JSON.parse(JSON.stringify(newQuestion.choices));
        let j = getRandomOptionId();
        const newOptions2 = newOptions.map((option) => {
            option.id = undefined;
            option.tempId = j++;
            return option
        })
        newQuestion.choices = [...options.filter((o) => (o.value ?? '').trim() !== '' || o.text !== ''), ...newOptions2];
        newQuestion.errorStatus = true;
        if (newQuestion.status !== ADDED)
            newQuestion.status = UPDATED;
        let newQuestionList = tabHeaderFooterData.pages[currentPage].elements.map((ques, idx) => {
            if (ques.tempId === newQuestion.tempId)
                return newQuestion;
            return ques;
        });
        setRandomOptionId(j);
        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, index) => {
                if (index === currentPage) {
                    return {
                        ...page,
                        elements: newQuestionList
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });
    }

    const onChange = (e, type, question, isSelect = false, optionfield = '') => {
        setChanges((a) => a + 1);

        let newOption = undefined;
        let oldOption = undefined;

        let newQuestion = JSON.parse(JSON.stringify(question));

        if (type == "questionText") {
            e.preventDefault();
            newQuestion[type] = e.target.value;
        } else if (type == "questionDescription") {
            e.preventDefault();
            newQuestion[type] = e.target.value;
        }
        else if (type === "selectListOptions") {

            // let choices = e.target.value.replace(/\n(?!\s)/g, '');
            newQuestion.choices = [e.target.value];

        }
        else if (type === "options") {
            let options = JSON.parse(JSON.stringify(question.choices));
            newOption = e.target.value;
            oldOption = options[e.target.index][optionfield];
            options[e.target.index][optionfield] = e.target.value;
            if (!newQuestion.codedOption) {
                options[e.target.index].value = e.target.value;
            }

            newQuestion.choices = options;

        } else if (type === "imageOptions") {
            let options = JSON.parse(JSON.stringify(question.choices));
            newOption = e.target.value;
            oldOption = options[e.target.index][optionfield];
            options[e.target.index][optionfield] = e.target.value;

            newQuestion.choices = options;

        } else if (type === "booleanYesOptions") {

            newQuestion.labelTrue = e.target.value;
        }
        else if (type === "booleanNoOptions") {

            newQuestion.labelFalse = e.target.value;
        }
        else if (type === 'isMandatory') {
            newQuestion[type] = e.value;
        }
        else if (type === 'requiredErrorText') {
            newQuestion[type] = e.target.value;
        } else if (type === 'choicesOrder') {
            newQuestion[type] = e;
        } else if (type === 'webOptions') {
            newQuestion[type] = e;
        } else if (type === 'mobileOptions') {
            newQuestion[type] = e;
        } else if (type === 'placeHolderText') {
            newQuestion[type] = e.target.value;
        } else if (type === 'maxValue') {
            newQuestion[type] = parseInt(e.target.value);
        } else if (type === 'minValue') {
            newQuestion[type] = parseInt(e.target.value);
        } else if (type === 'rateMax') {
            newQuestion[type] = e;
        } else if (type === 'step') {
            newQuestion[type] = parseInt(e.target.value);
        } else if (type === 'others') {
            newQuestion[type] = e.value;
        } else if (type === 'none') {
            newQuestion[type] = e.value;
        } else if (type === 'codedOption') {
            newQuestion[type] = e.value;
        }

        if (type === 'others' && newQuestion.others === true) {
            let j = getRandomOptionId();

            newQuestion.choices.push({ id: '', text: 'Other', value: 'Other', tempId: j++, optionType: OTHER });
            // newQuestion.none = false;
            setRandomOptionId(j);
        } else if (type === 'others' && newQuestion.others === false) {
            oldOption = 'Other';
            newQuestion.choices = newQuestion.choices.filter((option) => option.optionType !== OTHER);
        } else if (type === 'none' && newQuestion.none === true) {
            let j = getRandomOptionId();
            newQuestion.choices = newQuestion.choices.filter((option) => option.optionType !== NONE);
            newQuestion.choices.push({ id: '', text: 'None', value: 'None', tempId: j++, optionType: NONE });
            setRandomOptionId(j);
        } else if (type === 'none' && newQuestion.none === false) {
            oldOption = 'None';
            newQuestion.choices = newQuestion.choices.filter((option) => option.optionType !== NONE);
        }
        const errors = validateQuestionsAndOptions(newQuestion);
        setError(errors);
        newQuestion.errorStatus = (errors && Object.keys(errors).length > 0) ? true : false;

        let newQuestionList = tabHeaderFooterData.pages[currentPage].elements.map((ques, idx) => {
            if (ques.tempId === newQuestion.tempId)
                return newQuestion;

            return ques;
        });
        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, index) => {
                if (index === currentPage) {
                    return {
                        ...page,
                        elements: newQuestionList
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });
    }
    const onDLChange = (e, type, question) => {
        console.log(e, type, question, "onChange parent");
        let newQuestion = JSON.parse(JSON.stringify(question));

        if (type === 'DLFormulaText') {
            newQuestion[type] = e;
        }

        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, index) => {
                if (index === currentPage) {
                    const updatedElements = page.elements.map((ques, idx) => {
                        if (ques.tempId === newQuestion.tempId) {
                            return newQuestion;
                        }
                        return ques;
                    });
                    return {
                        ...page,
                        elements: updatedElements
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });
    };

    const saveDLForEachQuestion = (updatedQuestion) => {
        console.log("save for wach", updatedQuestion);
        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, index) => {
                if (index === currentPage) {
                    const updatedElements = page.elements.map((ques, idx) => {
                        if (ques.tempId === updatedQuestion.tempId) {
                            return updatedQuestion;
                        }
                        return ques;
                    });
                    return {
                        ...page,
                        elements: updatedElements
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });
    }
    const moveQuestion = useCallback(
        (dragIndex, hoverIndex) => {
            setChanges((a) => a + 1);
            const draggedQuestion = tabHeaderFooterData.pages[currentPage].elements[dragIndex];
            let map = {};
            // draggedQuestion.tempId = 'Q' + (currentPage + 1) + '-' + hoverIndex;
            draggedQuestion.relatedQuestionId = '';//should be array
            draggedQuestion.relatedAnswer = [];//should be array
            draggedQuestion.pevRel = undefined;
            if (draggedQuestion.status !== ADDED)
                draggedQuestion.status = UPDATED;
            let dependentIds = map[draggedQuestion.tempId] || [];
            let newQuestionList = [...tabHeaderFooterData.pages[currentPage].elements];
            if (dependentIds.length > 0)
                newQuestionList = tabHeaderFooterData.pages[currentPage].elements.map((ques, idx) => {
                    if (dependentIds.find((s) => s === ques.tempId) !== undefined || idx === 0) {
                        ques.relatedQuestionId = '';//should be array
                        ques.relatedAnswer = [];//should be array
                        ques.pevRel = undefined;
                        if (ques.status !== ADDED)
                            ques.status = UPDATED;
                        // ques.dLText = 'Q' + (idx + 1) + '.' + ques.questionText;
                        return ques;
                    }
                    // ques.dLText = 'Q' + (idx + 1) + '.' + ques.questionText;
                    return ques;
                });
            map[draggedQuestion.tempId] = undefined;
            setActiveEditTab(0);
            setActiveQuestion(hoverIndex);

            setTabHeaderFooterData(prevState => {
                const updatedPages = prevState.pages.map((page, index) => {
                    if (index === currentPage) {
                        const newElements = [...page.elements];
                        newElements.splice(dragIndex, 1);
                        newElements.splice(hoverIndex, 0, draggedQuestion);

                        return {
                            ...page,
                            elements: newElements
                        };
                    }
                    return page;
                });

                return {
                    ...prevState,
                    pages: updatedPages
                };
            });


        },
        [tabHeaderFooterData.pages[currentPage].elements],
    )

    const remove = (e, index) => {
        setChanges((a) => a + 1);
        const deletedQuestion = tabHeaderFooterData.pages[currentPage].elements[index];
        const newQuestionList = tabHeaderFooterData.pages[currentPage].elements.filter((ques, idx) => idx !== index);
        let updatedActiveQuestion = activeQuestion;

        if (activeQuestion > 0) {
            if (activeQuestion === index || activeQuestion !== questionListSize)
                updatedActiveQuestion = activeQuestion - 1;
            else if (activeQuestion === questionListSize)
                updatedActiveQuestion = questionListSize - 2;
        }

        setTabHeaderFooterData(prevState => {
            const updatedPages = prevState.pages.map((page, pageIndex) => {
                if (pageIndex === currentPage) {
                    return {
                        ...page,
                        elements: newQuestionList
                    };
                }
                return page;
            });
            return {
                ...prevState,
                pages: updatedPages
            };
        });

        setActiveQuestion(updatedActiveQuestion);
    };


    const save = () => {
        console.log("Saved Questions", tabHeaderFooterData)
        const { status: isFine, msg } = validateAllQuestions(tabHeaderFooterData);
        if (isFine === false) {
            dispatch(loadToaster({ type: 'warn', title: 'Validation Failed', msg: msg }))
        }
        else {
            tabHeaderFooterData.pages = tabHeaderFooterData.pages.map((page) => {
                const updatedElements = page.elements.map((element) => {
                    if (element.selectedType.questionType === 'selectList'
                        || element.selectedType.questionType === 'ranking'
                    ) {
                        const updatedChoices = element.choices.map((choice) => {
                            return choice.replace(/\s*,\s*/g, ',').replace(/\s*\n$/, '');
                        });
                        return {
                            ...element,
                            choices: updatedChoices,
                        };
                    }
                    return element;
                });
                return {
                    ...page,
                    elements: updatedElements,
                };
            });


            // const obj = tabHeaderFooterData;

            // // Convert the object to a string
            // const text = JSON.stringify(obj, null, 2);

            // // Create a new Blob object with the text content
            // const blob = new Blob([text], { type: 'text/plain' });

            // // Generate a URL for the file
            // const url = URL.createObjectURL(blob);

            // // Create a temporary anchor element for the download
            // const a = document.createElement('a');
            // a.download = 'SavedUpdatedQuestions.txt'; // set the desired filename
            // a.href = url;

            // // Simulate a click on the anchor to initiate the download
            // document.body.appendChild(a);
            // a.click();
            // document.body.removeChild(a);

            // // Release the object URL to free up memory
            // URL.revokeObjectURL(url);

            let postObject = {
                survey_id: currentSurveyID,
                user_id: userLoginDetails.user_id,
                survey_definition: JSON.stringify(tabHeaderFooterData)
            }
            dispatch(createOrUpdateSurveyDefinition(postObject));
        }


    }

    const toggleHeaderFooter = (value) => {
        setActiveHeaderFooter(value);
        setActiveHeaderEditTab(0);
        setActiveQuestion(null);
    };

    const HeaderSettingsOnChange = (e, type, header) => {
        let updatedHeaderData = JSON.parse(JSON.stringify(header));

        if (type === "surveyheadingForeground") {
            updatedHeaderData[type] = e.value;
            updatedHeaderData.surveyheadingForeground.color = `#${e.value}`;
        } else if (type === "surveyheadingBackgroundColor") {
            updatedHeaderData.surveyheadingBackground["background-color"] = `#${e.value}`;
        } else if (type === "logoPosition") {
            updatedHeaderData.logoPosition = e;
        }
        const errors = validateHeader(updatedHeaderData);
        setError(errors);
        updatedHeaderData.headerErrorStatus = (errors && Object.keys(errors).length > 0) ? true : false;

        setTabHeaderFooterData(prevState => ({
            ...prevState,
            header: updatedHeaderData
        }));
    };


    const HeaderDetaisOnChange = (e, type, question) => {
        console.log(question);
        let updatedHeaderData = JSON.parse(JSON.stringify(tabHeaderFooterData.header));

        if (type === "title") {
            updatedHeaderData.title = e.target.value;
        } else if (type === "description") {
            updatedHeaderData.description = e.target.value;
        } else if (type === "logo") {
            updatedHeaderData.logo = e.target.value;
        }
        const errors = validateHeader(updatedHeaderData);
        setError(errors);
        updatedHeaderData.headerErrorStatus = (errors && Object.keys(errors).length > 0) ? true : false;

        setTabHeaderFooterData(prevState => ({
            ...prevState,
            header: updatedHeaderData
        }));
    };

    const FooterSettingsOnChange = (e, type, question) => {
        let updatedFooterData = JSON.parse(JSON.stringify(tabHeaderFooterData.footer));

        if (type === "nextbuttonstyle") {
            updatedFooterData.nextbuttonstyle["background-color"] = `#${e.value}`;
        } else if (type === "footerBackgroundcolor") {
            updatedFooterData.footerBackgroundcolor = `#${e.value}`;
        } else if (type === "progressbar") {
            updatedFooterData.progressbar = e.target.value;
        } else if (type === "nextbuttonlabel") {
            updatedFooterData.nextbuttonlabel = e.target.value;
        } else if (type === "previousbuttonlabel") {
            updatedFooterData.previousbuttonlabel = e.target.value;
        } else if (type === "previousbuttonstyle") {
            updatedFooterData.previousbuttonstyle["background-color"] = `#${e.value}`;
        }
        const errors = validateFooter(updatedFooterData);
        setError(errors);
        updatedFooterData.footerErrorStatus = (errors && Object.keys(errors).length > 0) ? true : false;

        setTabHeaderFooterData(prevState => ({
            ...prevState,
            footer: updatedFooterData
        }));
    };


    const addQuestionnaireList = () => {
        setTabHeaderFooterData(prevState => ({
            ...prevState,
            pages: [
                ...prevState.pages,
                {
                    elements: []
                }
            ]
        }));

    }
    const onNextPageClick = () => {
        setCurrentPage((prevState) => prevState + 1)
    }
    const onPreviousPageClick = () => {
        setCurrentPage((prevState) => prevState - 1)
    }
    const onDeletePage = () => {
        setDeletePopUp(true);
    }

    const deletePage = () => {
        console.log(currentPage, "Delete Clicked");

        const updatedPages = [...tabHeaderFooterData.pages];

        // Remove the current page
        updatedPages.splice(currentPage, 1);

        // Check if mergeQuestions flag is true and the current page is not the first page
        if (mergeQuestions && currentPage > 0) {
            const previousPageQuestions = updatedPages[currentPage - 1].elements;
            const deletedPageQuestions = tabHeaderFooterData.pages[currentPage].elements;

            // Merge the questions of the deleted page with the previous page's questions
            updatedPages[currentPage - 1].elements = previousPageQuestions.concat(
                deletedPageQuestions
            );
        }

        setTabHeaderFooterData((prevState) => ({
            ...prevState,
            pages: updatedPages,
        }));

        setDeletePopUp(false);
        setCurrentPage((prevState) => prevState - 1);
        setMergeQuestions(false);

    };


    const hideDialog = () => {
        setDeletePopUp(false);
        setMergeQuestions(false);
    }

    const renderFooter = () => {
        return (
            <div>
                <Button name='yes' label="Delete" icon="pi pi-check" onClick={deletePage} />
                <Button name='no' label="Cancel" icon="pi pi-times" onClick={hideDialog} className="p-button-secondary" />
            </div>
        );
    }

    const handleMergeCheckboxChange = (event) => {
        setMergeQuestions(event.checked);
    };

    return (
        <div style={{
            // border: '1px solid #d3caca',
            // borderRadius: '10px', 
            margin: '5px'
        }}>
            <PageRefreshDialog />
            <div className="d-flex justify-content-between py-3" >
                <div className="col-md-auto">
                    <div className="row align-items-center"
                        style={{ fontWeight: 'bold', margin: 0 }}>
                        <div className="col-md-auto">
                            <span>Current Survey :
                                <span>{currentSurveyName}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-auto" style={{ textAlign: 'right' }}>
                    <Button
                        name="Save"
                        label="Save"
                        className="p-button-success"
                        style={{ 'backgroundColor': '#68b828' }}
                        icon="pi pi-save"
                        onClick={save}
                    />
                </div>
            </div>

            <div className="row"
            >
                {/* //Questionnaire */}

                <div className="col-xs-12 col-sm-2"
                    style={{
                        border: '1px solid #d3caca',
                        borderRadius: '10px',
                        // height: 'calc(100vh - 150px)',
                        backgroundColor: 'white',
                        color: 'black',
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: '5px',
                        marginLeft: 'auto'
                    }}
                >
                    <div className="row question-header">Questionnaire</div>
                    <div>
                        <div className={`row question survey-question-header ${activeHeaderFooter === 'header' ? 'active' : ''}`}
                        // style={{ marginLeft: '0px' }}
                        >

                            <div className="question-text"
                                onClick={() => toggleHeaderFooter('header')}
                                style={{ backgroundColor: activeHeaderFooter === 'header' ? '#0098f3' : 'transparent', color: activeHeaderFooter === 'header' ? '#fff' : '#000' }}
                            >
                                {tabHeaderFooterData.header.headerErrorStatus &&
                                    tabHeaderFooterData.header.headerErrorStatus === true ?
                                    <i className="fa fa-exclamation-circle"></i>
                                    : <></>}
                                Survey Header</div>
                        </div>


                    </div>
                    <div className="d-flex align-items-center justify-content-between p-3 bg-light survey-question-page-count">
                        <div className="d-flex align-items-center flex-grow-1">
                            {currentPage !== 0 && (
                                <span className="" style={{ cursor: 'pointer', order: 0 }}>
                                    <i className="fa fa-arrow-left fa-fw" onClick={onPreviousPageClick} />
                                </span>
                            )}
                            <span className="flex-grow-1 text-center">Page {currentPage + 1}/{tabHeaderFooterData.pages.length}</span>
                            {currentPage !== 0 && (
                                <span className="" style={{ cursor: 'pointer', order: 0 }}>
                                    <i className="fa fa-trash-o" onClick={onDeletePage} />
                                </span>
                            )}
                            {tabHeaderFooterData.pages.length > 1 && currentPage < tabHeaderFooterData.pages.length - 1 && (
                                <span className="" style={{ cursor: 'pointer' }}>
                                    <i className="fa fa-arrow-right fa-fw" onClick={onNextPageClick} />
                                </span>
                            )}
                        </div>
                        <span className="ml-2" style={{ cursor: 'pointer' }}>
                            <i className="fa fa-plus fa-fw" onClick={addQuestionnaireList} />
                        </span>
                    </div>



                    <DndProvider backend={HTML5Backend}>

                        <QuestionsList
                            questions={tabHeaderFooterData.pages[currentPage].elements}
                            // onClick={setActiveQuestion}
                            onClick={setActiveQuestion}
                            selected={activeQuestion}
                            setActiveHeaderFooter={setActiveHeaderFooter}
                            // clone={clone} 
                            remove={remove}
                            moveQuestion={moveQuestion}
                            // callConfirmation={callConfirmation}
                            currentPage={currentPage}
                        />
                    </DndProvider>

                    <div>

                        <div className={`row question survey-question-header ${activeHeaderFooter === 'footer' ? 'active' : ''}`}
                        >
                            <div className="question-text"
                                onClick={() => toggleHeaderFooter('footer')}
                                style={{ backgroundColor: activeHeaderFooter === 'footer' ? '#0098f3' : 'transparent', color: activeHeaderFooter === 'footer' ? '#fff' : '#000' }}
                            > {tabHeaderFooterData.footer.footerErrorStatus &&
                                tabHeaderFooterData.footer.footerErrorStatus === true ?
                                <i className="fa fa-exclamation-circle"></i>
                                : <></>}
                                Survey Footer</div>
                        </div>
                    </div>
                </div>

                {/* Question Div */}
                <div className="col-xs-12 col-sm-7 tab-view"
                    style={{
                        backgroundColor: 'white', color: 'black',
                        border: '1px solid #d3caca',
                        borderRadius: '10px',
                    }}
                >

                    {activeHeaderFooter === 'header' && (
                        <TabView activeIndex={activeHeaderEditTab} onTabChange={(e) => setActiveHeaderEditTab(e.index)}>
                            <TabPanel headerClassName="tab" header="Details">
                                <DetailsContent
                                    dataContent={tabHeaderFooterData.header}
                                    onChange={HeaderDetaisOnChange}
                                    error={error}
                                    setError={setError}
                                />
                            </TabPanel>
                            <TabPanel headerClassName="tab" header="Settings">
                                <SettingsContent
                                    dataContent={tabHeaderFooterData.header}
                                    onChange={HeaderSettingsOnChange}
                                />
                            </TabPanel>
                        </TabView>
                    )}


                    {activeHeaderFooter !== 'header' && activeHeaderFooter !== 'footer' && (

                        <TabView activeIndex={activeEditTab} onTabChange={(e) => setActiveEditTab(e.index)}>
                            <TabPanel headerClassName="tab" header="Details">
                                <Content
                                    question={tabHeaderFooterData.pages[currentPage].elements[activeQuestion]}
                                    // questionnaireType={questionnaireType}
                                    onChange={onChange}
                                    addOption={addOption}
                                    matrixAddOption={matrixAddOption}
                                    addMultipleOptions={addMultipleOptions}
                                    removeOption={removeOption}
                                    matrixRemoveOption={matrixRemoveOption}
                                    error={error}
                                    setError={setError}
                                />
                            </TabPanel>
                            <TabPanel headerClassName="tab" header="Settings">
                                <Settings
                                    question={tabHeaderFooterData.pages[currentPage].elements[activeQuestion]}
                                    onChange={onChange}
                                // questionnaireType={questionnaireType}
                                // callConfirmation={callConfirmation}
                                />
                            </TabPanel>
                            <TabPanel headerClassName="tab" header="Display logic">
                                <DisplayLogic
                                    question={tabHeaderFooterData.pages[currentPage].elements[activeQuestion]}
                                    questionList={tabHeaderFooterData.pages}
                                    onChange={onDLChange}
                                    currentPage={currentPage}
                                    saveDLForEachQuestion={saveDLForEachQuestion}
                                    // reset={resetDisplayLogic}
                                    activeQuestion={activeQuestion} />

                            </TabPanel>
                        </TabView>
                    )}

                    {activeHeaderFooter === 'footer' && (
                        <TabView activeIndex={activeHeaderEditTab} onTabChange={(e) => setActiveHeaderEditTab(e.index)}>
                            <TabPanel headerClassName="tab" header="Settings">
                                <FooterSettings
                                    dataContent={tabHeaderFooterData.footer}
                                    onChange={FooterSettingsOnChange}
                                    error={error}
                                    setError={setError}
                                />
                            </TabPanel>
                        </TabView>
                    )}
                </div>

                {/* QuestionsTypeList */}

                <div className="col-xs-12 col-sm-2"
                    style={{
                        border: '1px solid #d3caca',
                        borderRadius: '10px', backgroundColor: 'white',
                        color: 'black',
                        marginRight: 'auto',
                        marginLeft: '5px'
                    }}>
                    <div className="row question-header">Add Question</div>
                    <QuestionTypeList onClick={addNewQuestion} />

                </div>
            </div>


            <Dialog
                header="Confirmation Required"
                visible={deletePopUp}
                style={{ width: '40vw' }}
                onHide={hideDialog}
                footer={renderFooter()}
                contentStyle={{ height: '90px' }}
            >
                <p>
                    You cannot undo this action. <b>Are you sure you want to proceed?</b>
                </p>
                <div className="p-d-flex p-align-center">
                    <Checkbox id="mergeCheckbox" onChange={handleMergeCheckboxChange} checked={mergeQuestions} />
                    <label style={{ marginLeft: '10px', marginBottom: 0 }} htmlFor="mergeCheckbox">
                        {`Do you want to merge these questions on page ${currentPage}?`}
                    </label>
                </div>
            </Dialog>


        </div >
    )
}

export default Design