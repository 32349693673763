import { isNullorEmpty } from "../../../../utlities/validation-util";

export const validateQuestionsAndOptions = (question) => {
    let errors = {};

    if (isNullorEmpty(question.questionText)) {
        errors.question = "Question description required";
    } else if (question.questionText.length > 1024) {
        errors.question = "Question description should contain max 1024 characters ";
    }
    if (question.type === 'boolean') {
        if (isNullorEmpty(question.labelTrue)) {
            errors.option = "Option description required";
        }
        if (isNullorEmpty(question.labelFalse)) {
            errors.option = "Option description required";
        }
    }

    if (question.isMandatory === true && isNullorEmpty(question.requiredErrorText)) {
        errors.question = "If Question is Mandatory then Required Text should not be empty";
    }

    if (question.type === 'numeric' || question.type === 'sliderScale') {
        if (question.minValue || question.maxValue) {
            const minValue = Number.parseInt(question.minValue) !== NaN ? Number.parseInt(question.minValue) : undefined;
            const maxValue = Number.parseInt(question.maxValue) !== NaN ? Number.parseInt(question.maxValue) : undefined;

            if (minValue > maxValue) {
                errors.option = "min value is greater than max value";
                return errors;
            }
        }
    }

    if (question.type === 'selectList' || question.type === 'ranking') {
        const choices = Array.isArray(question.choices) ? question.choices : [];

        if (choices.length === 0 || choices.some(option => isNullorEmpty(option.trim()))) {
            errors.option = 'Option description required';
            return errors;
        }

        let optionTexts = choices[0]?.split(',') || [];

        optionTexts = optionTexts.map((o) => o.trim()).filter(Boolean);

        if (new Set(optionTexts).size !== optionTexts.length) {
            errors.option = 'Duplicate option description';
            return errors;
        }
    }

    if (question.type === 'matrix' || question.type === 'matrixmultiChoice') {
        const rows = Array.isArray(question?.rows) ? question?.rows : [];


        if (rows.length === 0 || rows.some(option => isNullorEmpty(option.trim()))) {
            errors.option = 'Rows required';
            return errors;
        }

        let optionRows = rows[0]?.split(',') || [];

        optionRows = optionRows.map((o) => o.trim()).filter(Boolean);

        if (new Set(optionRows).size !== optionRows.length) {
            errors.option = 'Duplicate option description';
            return errors;
        }

        const cols = Array.isArray(question?.columns) ? question?.columns : [];

        if (cols.length === 0 || cols.some(option => isNullorEmpty(option.trim()))) {
            errors.option = 'Columns required';
            return errors;
        }

        let optionCols = rows[0]?.split(',') || [];

        optionCols = optionCols.map((o) => o.trim()).filter(Boolean);

        if (new Set(optionCols).size !== optionCols.length) {
            errors.option = 'Duplicate option description';
            return errors;
        }
    }

    if (
        question.type === 'radiogroup' ||
        question.type === 'checkbox' ||
        question.type === 'singleDropdown' ||
        question.type === 'multiselectdropdown' ||
        question.type === 'imagepicker'
    ) {
        for (const option of question.choices) {
            if (isNullorEmpty(option.value)) {
                errors.option = 'Option description required';
                return errors;
            } else if (option.value.length > 255) {
                errors.option = 'Option description should contain max 255 characters ';
                return errors;
            }

            if (question.type === 'imagepicker' && isNullorEmpty(option.imageLink)) {
                errors.option = 'Image should be uploaded!';
                return errors;
            }
        }

        let optionTexts = question.choices.map((o) => o.value);
        if (new Set(optionTexts).size !== question.choices.length) {
            errors.option = 'Duplicate option description';
            return errors;
        }
    }


    return errors;
}

export const validateHeader = (question) => {
    let errors = {};

    if (isNullorEmpty(question.title)) {
        errors.title = "Title required";
    }

    if (isNullorEmpty(question.logo)) {
        errors.logo = "logo required";
    }
    if (isNullorEmpty(question.surveyheadingForeground.color)) {
        errors.title = "Survey Heading Text Color is required";
    }

    if (isNullorEmpty(question.surveyheadingBackground["background-color"])) {
        errors.title = "Survey Heading Background Color is required";
    }

    return errors;
}

export const validateFooter = (question) => {
    let errors = {};

    if (isNullorEmpty(question.nextbuttonlabel)) {
        errors.nextLabel = "Next Button Label required";
    }

    if (isNullorEmpty(question.previousbuttonlabel)) {
        errors.previousLabel = "Previous Button Label required";
    }

    return errors;
}

export const validateAllQuestions = (questionnaireData) => {
    let errors = {};
    errors.status = false;

    // Validate header
    const headerErrors = validateHeader(questionnaireData.header);
    if (Object.keys(headerErrors).length > 0) {
        return {
            status: false,
            msg: 'Unable to save the Questionnaire, header validation failed',
            ...headerErrors
        };
    }

    // Validate footer
    const footerErrors = validateFooter(questionnaireData.footer);
    if (Object.keys(footerErrors).length > 0) {
        return {
            status: false,
            msg: 'Unable to save the Questionnaire, footer validation failed',
            ...footerErrors
        };
    }
    const questionList = questionnaireData.pages.flatMap((page) => page.elements);
    if (questionList.length === 0) {
        return {
            status: false,
            msg: 'Unable to save the Questionnaire, no questions to save'
        };
    }

    for (const question of questionList) {
        const questionErrors = validateQuestionsAndOptions(question);
        if (Object.keys(questionErrors).length > 0) {
            return {
                status: false,
                msg: 'Unable to save the Questionnaire, validation failed for some questions',
                ...questionErrors
            };
        }
    }

    return {
        status: true,
        msg: 'no errors'
    };
}

