import React, { useRef } from 'react';
import Compressor from 'compressorjs';
import { getBase64 } from '../../../utlities/validation-util';
import Button from '../Button/custom-button';

const CompressedImageUpload = ({ onChange }) => {
  const myRef = useRef();

  const onFileChange = async (e) => {
    const targetFile = e.target.files[0];
    if (targetFile && targetFile.type.startsWith("image/")) {
      const val = await getBase64(targetFile);
      new Compressor(targetFile, {
        quality: 0.95,
        maxHeight: 400,
        maxWidth: 600,
        minHeight: 200,
        minWidth: 300,
        convertSize: 500000,
        async success(result) {
          const val2 = await getBase64(result);
          let base64Length = val2.length - (val2.indexOf(',') + 1);
          let padding = val2.charAt(val2.length - 2) === '=' ? 2 : val2.charAt(val2.length - 1) === '=' ? 1 : 0;
          let fileSize = (base64Length * 0.75 - padding) / 1000;
          if (fileSize < 500) {
            onChange(val2);
          } else {
            // dispatch(loadToaster({ type: 'warn', title: 'Failed to upload Image', msg: 'Image size has exceeded 500KB' }));
          }
        },
        error(err) {
          console.log("compress error", err.message);
        },
      });
    } else {
    //   dispatch(loadToaster({ type: 'warn', title: 'Failed to upload Image', msg: 'Please upload a file of type Image' }));
      if (myRef && myRef.current) {
        myRef.current.value = '';
      }
    }
  };

  return (
    <>
      <Button
        name="addImages"
        label="Image"
        className="p-button-primary margin-right"
        icon="pi pi-plus"
        onClick={() => { myRef.current.click(); }}
      />
      <input
        type="file"
        onChange={onFileChange}
        className="customm-file-input"
        style={{ display: 'none' }}
        ref={myRef}
        accept="image/*"
      />
    </>
  );
};

export default CompressedImageUpload;
