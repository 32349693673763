import React, { useState, useCallback, useEffect } from 'react'
import { InputTextarea } from 'primereact/inputtextarea';
import '../design.css'
import InfoIcon from '../../../shared-components/Info-Icon/InfoIcon';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from "primereact/dropdown";
import { ColorPicker } from 'primereact/colorpicker';
import { validateFooter } from '../Helpers/Validations';

const FooterSettings = (props) => {
    const { dataContent, onChange, error, setError } = props;
    const [bgColor, setBgColor] = useState(
        dataContent.footerBackgroundcolor
    );
    const [nextButtonColor, setNextButtonColor] = useState(dataContent.nextbuttonstyle["background-color"]);
    const [previousButtonColor, setPreviousButtonColor] = useState(dataContent.previousbuttonstyle["background-color"]);

    useEffect(() => {
        console.log("hey eeror", error);
    }, [error])

    useEffect(() => {
        if (dataContent)
            setError(validateFooter(dataContent))
        return () => { }
    }, [dataContent])

    function debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        }
    }

    const debouncedOnChange = useCallback(
        debounce(
            (event) =>
                onChange(event, 'footerBackgroundcolor'),
            500
        ),
        []
    );

    const onChangeColor = (event) => {
        setBgColor(`#${event.value}`);
        debouncedOnChange(event);
    };

    const debouncedOnTitleChange = useCallback(
        debounce(
            (event) =>
                onChange(event, 'nextbuttonstyle'),
            500
        ),
        []
    );
    const onNextButtonColorChange = (event) => {
        setNextButtonColor(`#${event.value}`);
        debouncedOnTitleChange(event);
    };
    const onPreviousButtonColorChange = (event) => {
        setPreviousButtonColor(`#${event.value}`);
        debouncedOnPreviousChange(event);
    };
    const debouncedOnPreviousChange = useCallback(
        debounce(
            (event) =>
                onChange(event, 'previousbuttonstyle'),
            500
        ),
        []
    );
    const handlePrevBtnColor = (event) => {
        const value = event.target.value;
        // Check if the input value is a valid color code
        if (/^#[0-9A-F]{6}$/i.test(value)) {
            onPreviousButtonColorChange(value);
        }
        setPreviousButtonColor(value);

    }

    const handleNxtBtnColor = (event) => {
        const value = event.target.value;
        // Check if the input value is a valid color code
        if (/^#[0-9A-F]{6}$/i.test(value)) {
            onNextButtonColorChange(value);
        }
        setNextButtonColor(value);
    }
    const handleFooterTextColor = (event) => {
        const value = event.target.value;
        // Check if the input value is a valid color code
        if (/^#[0-9A-F]{6}$/i.test(value)) {
            onChangeColor(value);
        }
        setBgColor(value);
    }
    return (
        <div className=''>
            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                    Progress Bar
                </div>
                <div className="col-7 d-flex justify-content-start">
                    <InputSwitch checked={dataContent.progressbar}
                        onChange={(e) => onChange(e, 'progressbar', dataContent)}
                    />
                </div>
            </div>

            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                    Next Button Label
                </div>
                <div className="col-7 d-flex justify-content-start">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <input type="text" style={{ 'fontSize': 'normal' }}
                            placeholder="Enter your text Here"
                            onChange={(e) => { onChange(e, 'nextbuttonlabel', dataContent) }}
                            value={dataContent.nextbuttonlabel} />
                        {error.nextLabel && <span className="error-message"> {error.nextLabel}</span>}

                    </div>
                </div>
            </div>
            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                    Next Button Colour
                </div>
                <div className="col-7 d-flex justify-content-start">
                    <input type="text" value={`${nextButtonColor}`} onChange={handleNxtBtnColor} />
                    <ColorPicker
                        value={nextButtonColor}
                        onChange={onNextButtonColorChange}
                        style={{ width: '50%' }}
                    />
                </div>
            </div>
            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                    Previous Button Label
                </div>
                <div className="col-7 d-flex justify-content-start align-items-start">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <input type="text" style={{ fontSize: 'normal' }}
                            placeholder="Enter your text Here"
                            onChange={(e) => { onChange(e, 'previousbuttonlabel', dataContent) }}
                            value={dataContent.previousbuttonlabel} />
                        {error.previousLabel && <span className="error-message"> {error.previousLabel}</span>}
                    </div>
                </div>
            </div>

            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                    Previous Button Colour
                </div>
                <div className="col-7 d-flex justify-content-start">
                    <input type="text" value={`${previousButtonColor}`} onChange={handlePrevBtnColor} />
                    <ColorPicker
                        value={previousButtonColor}
                        onChange={onPreviousButtonColorChange}
                        style={{ width: '50%' }}
                    />
                </div>
            </div>

            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                    Survey Footer Background Colour
                </div>
                <div className="col-7 d-flex justify-content-start">
                    <input type="text" value={`${bgColor}`} onChange={handleFooterTextColor} />
                    <ColorPicker
                        value={bgColor}
                        onChange={onChangeColor}
                        style={{ width: '50%' }}
                    />
                </div>
            </div>

        </div>
    )
}


export default FooterSettings