import React, { useState, useEffect } from 'react';
import './AppNew.css';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import Footer from './components/HomeNew/Footer/Footer';
import Header from './components/HomeNew/Header/Header';
import Topbar from './components/HomeNew/TopBar/TopBar';
import Design from './components/HomeNew/Design/Design';
import HomeProjectList from './components/HomeNew/ProjectList/HomeProjectList';
import Details from './components/HomeNew/Details/Details';
import Login from './components/HomeNew/Login/Login';
import Respondents from './components/HomeNew/Respondents/Respondents';
import Data from './components/HomeNew/Data/Data';
import Invitation from './components/HomeNew/Invitation/Invitation';
import Documentation from './components/HomeNew/Documentation/Documentation';

import { useDispatch } from 'react-redux';
import { startSpinner } from './components/shared-components/custom-spinner/custom-spinner-store/spinner-actions';
import { fetchUserDetals } from './components/HomeNew/Login/login-store/login-actions';
import { fetchLoginDetails } from './components/HomeNew/Login/login-store/login-actions-creators';
import { loadToaster } from './components/shared-components/toaster/toaster-actions-creators';
import EditDetails from './components/HomeNew/Details/Edit-Details/EditDetails';
import Dashboard from './components/HomeNew/Dashboard/Dashboard';

function ProtectedRoute({ component: Component, authenticated, ...rest }) {
  const isAuthenticated = localStorage.getItem('Authentication') !== null;
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchLoginDetails(JSON.parse(localStorage.getItem('Authentication'))));
    }
  }, [isAuthenticated]);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
}

function AppRoutes() {
  const [loggedIn, setLoggedIn] = useState(() => {
    const isLoggedIn = localStorage.getItem('loggedIn');
    return isLoggedIn === 'true';
  });
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch();

  const handleLogin = async (email, password) => {
    setLoggedIn(true);
    console.log("Email:", email);
    console.log("Password:", password);
    let getResponse = await dispatch(fetchUserDetals(`${email}/${password}`));
    localStorage.setItem('Authentication', JSON.stringify(getResponse));
    localStorage.setItem('loggedIn', 'true');
    history.push('/surveys/survey-lists');

    // setTimeout(() => {
    //   dispatch(startSpinner());

    // }, 300);
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('loggedIn');
    if (isLoggedIn === 'true') {
      setLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    setLoggedIn(false);
    // localStorage.setItem('loggedIn', 'false');
    localStorage.clear();
    history.push('/login');
    dispatch(loadToaster({ type: 'success', title: '', msg: 'LogOut SucessFul' }))

  };

  return (
    <div className="AppNew">
      {loggedIn && currentPath !== '/login' && (
        <>
          <Header onLogout={handleLogout} />
          {currentPath !== '/surveys/survey-lists' && <Topbar />}
        </>
      )}
      <div
        className={`content-wrapper ${currentPath === '/login' ? 'login-wrapper' : ''}`}
        style={currentPath === '/login' ? {} : { height: 'calc(100vh - 150px)', overflowY: 'scroll', overflowX: 'hidden' }}
      >
        <Switch>
          <Route exact path="/login">
            <Login onLogin={handleLogin} />
          </Route>

          <ProtectedRoute
            exact
            path="/surveys/survey-lists"
            component={HomeProjectList}
            authenticated={loggedIn}
          />

          <ProtectedRoute
            path="/surveys/edit-survey/:id/design"
            component={Design}
            authenticated={loggedIn}
          />

          <ProtectedRoute
            path="/surveys/create-survey"
            component={Details}
            authenticated={loggedIn}
          />

          <ProtectedRoute
            path="/surveys/edit-survey/:id/dashboard"
            component={Dashboard}
            authenticated={loggedIn}
          />

          <ProtectedRoute
            path="/surveys/edit-survey/:id/respondents"
            component={Respondents}
            authenticated={loggedIn}
          />

          <ProtectedRoute
            path="/surveys/edit-survey/:id/invitation"
            component={Invitation}
            authenticated={loggedIn}
          />

          <ProtectedRoute
            path="/surveys/edit-survey/:id/data"
            component={Data}
            authenticated={loggedIn}
          />

          <ProtectedRoute
            path="/surveys/edit-survey/:id/documentation"
            component={Documentation}
            authenticated={loggedIn}
          />

          <ProtectedRoute
            path="/surveys/edit-survey/:id"
            component={EditDetails}
            authenticated={loggedIn}
          />

          <Redirect to="/login" />
        </Switch>


      </div>
      {loggedIn && currentPath !== '/login' && (
        <Footer />
      )}
    </div>
  );
}

export default AppRoutes;
