export const SurveyQuestionTypes = [
    {
        id: 1,
        name: "",
        label: "Single Choice",
        options: "",
        questionType: 'radiogroup',
        icon: 'fa fa-dot-circle-o fa-fw'
    },
    {
        id: 2,
        name: "",
        label: "Multi Choice",
        options: "",
        questionType: 'checkbox',
        icon: 'fa fa-check-square-o fa-fw'
    },

    {
        id: 4,
        name: "",
        label: "Open Text",
        options: "",
        questionType: 'openText',
        icon: 'fa fa-square-o fa-fw'
    },
    {
        id: 5,
        name: "",
        label: "Numeric",
        options: "",
        questionType: 'numeric',
        icon: 'fa fa-calculator fa-fw'
    },
    {
        id: 6,
        name: "",
        label: "NPS",
        options: "",
        questionType: 'NPS',
        icon: 'fa fa-tachometer fa-fw'
    },
    {
        id: 3,
        name: "",
        label: "Select List",
        options: "",
        questionType: 'selectList',
        icon: 'fa fa-caret-square-o-down fa-fw'
    },
    {
        id: 7,
        name: "",
        label: "Date",
        options: "",
        questionType: 'date',
        icon: 'fa fa-calendar fa-fw'
    },
    {
        id: 8,
        name: "",
        label: "Rating",
        options: "",
        questionType: 'starRating',
        icon: 'fa fa-star fa-fw'
    },

    {
        id: 9,
        name: "",
        label: "Emoji",
        options: "",
        questionType: 'rating',
        icon: 'fa fa-smile-o fa-fw'
    },
    {
        id: 18,
        name: "",
        label: "Section",
        options: "",
        questionType: 'heading',
        icon: 'fa fa-bars fa-fw'
    },
    {
        id: 10,
        name: "",
        label: "Ranking",
        options: "",
        questionType: 'ranking',
        icon: 'fa fa-trophy'
    }, {
        id: 11,
        name: "",
        label: "Single Selection",
        options: "",
        questionType: 'singleDropdown',
        icon: 'fa fa-caret-down fa-fw'
    }, {
        id: 12,
        name: "",
        label: "Multi Selection Dropdown",
        options: "",
        questionType: 'multiselectdropdown',
        icon: 'fa fa-light fa-list-alt'
    }
    , {
        id: 13,
        name: "",
        label: "Yes or No",
        options: "",
        questionType: 'boolean',
        icon: 'fa fa-thumbs-up fa-fw'
    }
    , {
        id: 14,
        name: "",
        label: "Slider Scale",
        options: "",
        questionType: 'sliderScale',
        icon: 'fa fa-sliders'
    },

    {
        id: 15,
        name: "",
        label: "Matrix Single Choice",
        options: "",
        questionType: 'matrix',
        icon: 'fa fa-list'
    }, {
        id: 16,
        name: "",
        label: "Matrix Multi Choice",
        options: "",
        questionType: 'matrixmultiChoice',
        icon: 'fa fa-th-large'
    }
    ,
    {
        id: 17,
        name: "",
        label: "Image Selection",
        options: "",
        questionType: 'imagepicker',
        icon: 'fa fa-camera fa-fw'
    },



    // {
    //     id: 11,
    //     name: "",
    //     label: "Video Capture",
    //     options: "",
    //     questionType: 3,
    //     icon: 'fa fa-video-camera fa-fw'
    // }
]