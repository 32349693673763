import { TEST, FETCH_SURVEY_DEFINITION } from "./design-store-constants";

export function TestAction(data) {
    return {
        type: TEST,
        payload: {
            test: data
        }
    }
}
export function getSurveyDefinition(data) {
    return {
        type: FETCH_SURVEY_DEFINITION,
        payload: {
            surveyDefinitionData: data
        }
    }
}