import React, { useState, useEffect } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from 'primereact/inputtextarea';
import InfoIcon from '../../shared-components/Info-Icon/InfoIcon';

const Settings = (props) => {
    const { question, onChange, callConfirmation } = props;
    const [dateValue, setDateValue] = useState(true);
    const [selectedOption, setSelectedOption] = useState("normal");

    const yesNoChange = (e) => {
        if (e.target.value === false)
            onChange(e, 'yesOrNo', question);
        else
            callConfirmation({ callback: () => onChange(e, 'yesOrNo', question), msg: "This operation will remove all the existing options for this question." })
    }
    const dropDownOptions = [
        { label: "Randomized", value: "random" },
        { label: "Ascending", value: "ascending" },
        { label: "Descending", value: "descending" },
        { label: "Normal", value: "normal" }

    ];
    const ratingOptionsDropDown = [
        { label: 3, value: 3 },
        { label: 5, value: 5 },
        { label: 7, value: 7 },
    ];
    const webOptionsDropDown = [
        { label: 3, value: 3 },
        { label: 2, value: 2 },
        { label: 1, value: 1 },
    ];

    const mobileOptionsDropDown = [
        { label: 2, value: 2 },
        { label: 1, value: 1 },
    ];
    return (question ? <div className="">

        <div className="row align-items-center">
            <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                Is Mandatory
            </div>
            <InputSwitch checked={question.isMandatory}
                onChange={(e) => onChange(e, 'isMandatory', question)} />
        </div>


        {function () {
            if (question)
                switch (question.type) {
                    case 'radiogroup':
                        return (<>
                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    Other
                                </div>
                                <InputSwitch checked={question.others} onChange={(e) => onChange(e, 'others', question)} />
                            </div>
                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    None
                                </div>
                                <InputSwitch checked={question.none}
                                    onChange={(e) => onChange(e, 'none', question)} />
                            </div>
                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    Coded Option
                                </div>
                                <InputSwitch checked={question.codedOption}
                                    onChange={(e) => onChange(e, 'codedOption', question)} />
                            </div>
                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Is Randomized
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <Dropdown
                                        value={question.choicesOrder}
                                        options={dropDownOptions}
                                        defaultValue="normal"
                                        onChange={(e) => onChange(e.value, "choicesOrder", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                </div>
                            </div>


                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Web Options
                                </div>
                                <div className="col-7 d-flex justify-content-start" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Dropdown
                                        value={question.webOptions || 3}
                                        options={webOptionsDropDown}
                                        onChange={(e) => onChange(e.value, "webOptions", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                    <InfoIcon msg="This is option is used for how the options should be displayed in Web" />
                                </div>
                            </div>

                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Mobile Options
                                </div>
                                <div className="col-7 d-flex justify-content-start" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Dropdown
                                        value={question.mobileOptions || 2}
                                        options={mobileOptionsDropDown}
                                        onChange={(e) => onChange(e.value, "mobileOptions", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                    <InfoIcon msg="This is option is used for how the options should be displayed in Mobile" />
                                </div>
                            </div>


                        </>);
                    case 'checkbox':
                        return (<>

                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    Other
                                </div>
                                <InputSwitch checked={question.others} onChange={(e) => onChange(e, 'others', question)} />
                            </div>
                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    None
                                </div>
                                <InputSwitch checked={question.none}
                                    onChange={(e) => onChange(e, 'none', question)} />
                            </div>
                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    Coded Option
                                </div>
                                <InputSwitch checked={question.codedOption}
                                    onChange={(e) => onChange(e, 'codedOption', question)} />
                            </div>
                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Is Randomized
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <Dropdown
                                        value={question.choicesOrder}
                                        options={dropDownOptions}
                                        defaultValue="normal"
                                        onChange={(e) => onChange(e.value, "choicesOrder", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                </div>
                            </div>


                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Web Options
                                </div>
                                <div className="col-7 d-flex justify-content-start" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Dropdown
                                        value={question.webOptions || 3}
                                        options={webOptionsDropDown}
                                        onChange={(e) => onChange(e.value, "webOptions", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                    <InfoIcon msg="This is option is used for how the options should be displayed in Web" />
                                </div>
                            </div>

                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Mobile Options
                                </div>
                                <div className="col-7 d-flex justify-content-start" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Dropdown
                                        value={question.mobileOptions || 2}
                                        options={mobileOptionsDropDown}
                                        onChange={(e) => onChange(e.value, "mobileOptions", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                    <InfoIcon msg="This is option is used for how the options should be displayed in Mobile" />
                                </div>
                            </div>

                        </>);
                    case 'numeric':
                        return (<>
                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    Min Value
                                </div>
                                <div className="col-5 col-lg-3 px-0 p-inputtextarea">
                                    <input
                                        type="number"
                                        name="minValue"
                                        className="p-inputtext"
                                        style={{ width: '100%' }}
                                        value={question.minValue}
                                        onChange={(e) => onChange(e, 'minValue', question)}
                                    />
                                </div>

                            </div>
                            <div className="row align-items-center mt-2">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    Max Value
                                </div>
                                <div className="col-5 col-lg-3 px-0 p-inputtextarea">
                                    <input
                                        type="number"
                                        name="maxValue"
                                        className="p-inputtext"
                                        style={{ width: '100%' }}
                                        value={question.maxValue}
                                        onChange={(e) => onChange(e, 'maxValue', question)}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Place Holder Text
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <InputTextarea style={{ 'fontSize': 'normal' }} name="placeHolderText"
                                        placeholder="Enter Question Here"
                                        onChange={(e) => { onChange(e, 'placeHolderText', question) }}
                                        value={question.placeHolderText} autoResize={true} />
                                </div>
                            </div>

                        </>);
                    case 'starRating':
                        return (<>

                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Maximum Rating
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <Dropdown
                                        value={question.rateMax || 5}
                                        options={ratingOptionsDropDown}
                                        onChange={(e) => onChange(e.value, "rateMax", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                </div>
                            </div>
                        </>);
                    case 'rating':
                        return (<>
                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Maximum Rating
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <Dropdown
                                        value={question.rateMax || 5}
                                        options={ratingOptionsDropDown}
                                        onChange={(e) => onChange(e.value, "rateMax", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                </div>
                            </div>
                        </>);
                    case 'openText':
                        return (<>
                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Place Holder Text
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <InputTextarea style={{ 'fontSize': 'normal' }} name="placeHolderText"
                                        placeholder="Enter Question Here"
                                        onChange={(e) => { onChange(e, 'placeHolderText', question) }}
                                        value={question.placeHolderText} autoResize={true} />
                                </div>
                            </div>

                        </>);
                    case 'ranking':
                        return (<>
                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Is Randomized
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <Dropdown
                                        value={question.choicesOrder}
                                        options={dropDownOptions}
                                        defaultValue="normal"
                                        onChange={(e) => onChange(e.value, "choicesOrder", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                </div>
                            </div>



                        </>);
                    case 'singleDropdown':
                        return (<>
                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Is Randomized
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <Dropdown
                                        value={question.choicesOrder}
                                        options={dropDownOptions}
                                        defaultValue="normal"
                                        onChange={(e) => onChange(e.value, "choicesOrder", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                </div>
                            </div>



                        </>);
                    case 'multiselectdropdown':
                        return (<>
                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Is Randomized
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <Dropdown
                                        value={question.choicesOrder}
                                        options={dropDownOptions}
                                        defaultValue="normal"
                                        onChange={(e) => onChange(e.value, "choicesOrder", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                </div>
                            </div>



                        </>);
                    case 'imagepicker':
                        return (<>
                            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                                    Is Randomized
                                </div>
                                <div className="col-7 d-flex justify-content-start">
                                    <Dropdown
                                        value={question.choicesOrder}
                                        options={dropDownOptions}
                                        defaultValue="normal"
                                        onChange={(e) => onChange(e.value, "choicesOrder", question)}
                                        placeholder="Select an option"
                                        style={{ width: '50%' }}

                                    />
                                </div>
                            </div>



                        </>);
                    case 'sliderScale':
                        return (<>
                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    Min Value
                                </div>
                                <div className="col-5 col-lg-3 px-0">

                                    <input
                                        type="number"
                                        name="minValue"
                                        style={{ width: '100%' }}
                                        value={question.minValue}
                                        onChange={(e) => onChange(e, 'minValue', question)}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    Max Value
                                </div>
                                <div className="col-5 col-lg-3 px-0">

                                    <input
                                        type="number"
                                        name="maxValue"
                                        style={{ width: '100%' }}
                                        value={question.maxValue}
                                        onChange={(e) => onChange(e, 'maxValue', question)}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-5 tab-view-label" style={{ 'fontWeight': 'bold' }}>
                                    Step
                                </div>
                                <div className="col-5 col-lg-3 px-0">

                                    <input
                                        type="number"
                                        name="step"
                                        style={{ width: '100%' }}
                                        value={question.step}
                                        onChange={(e) => onChange(e, 'step', question)}
                                    />
                                </div>
                            </div>
                        </>);

                    default:
                        return <></>;
                }
        }()
        }
        <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                Error Text
            </div>
            <div className="col-7 d-flex justify-content-start">
                <InputTextarea style={{ 'fontSize': 'normal' }} name="requiredErrorText"
                    placeholder="Enter Question Here"
                    onChange={(e) => { onChange(e, 'requiredErrorText', question) }}
                    value={question.requiredErrorText} autoResize={true} />
            </div>
        </div>


    </div> : <div></div>)
}

export default Settings