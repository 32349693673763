import axios from "axios";
import { API_END_POINT } from "../../../../config";
import { startSpinner, stopSpinner } from "../../../shared-components/custom-spinner/custom-spinner-store/spinner-actions";
import { TestAction } from "./login-actions-creators";
import { loadToaster } from "../../../shared-components/toaster/toaster-actions-creators";

export const fetchTestCall = () => {
    return (dispatch) => {
        try {
            // dispatch(startSpinner());
            dispatch(TestAction("ravikrishnapunnam@gmail.com"));

        } catch (err) {

        }

    };
};
export const fetchUserDetals = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await axios.get(`${API_END_POINT.BASE_URL}/api/User/GetLogin/${postObject}`);
            let getDetails = response.data.data;      
            dispatch(loadToaster({ type: 'success', title: '', msg: 'Login SucessFul' }))     
            dispatch(stopSpinner());
            return getDetails;

        } catch (err) {
            console.error(err);
            dispatch(stopSpinner());
            throw err; // Throw the error to the caller
        }
    };
};
