import { combineReducers } from "redux";
import LoginReducer from "../components/HomeNew/Login/login-store/login-reducers";
import ProjectListReducer from "../components/HomeNew/ProjectList/home-store/home-reducers";
import SpinnerReducer from "../components/shared-components/custom-spinner/custom-spinner-store/spinner-reducers";
import { ToastReducer } from "../components/shared-components/toaster/toaster-reducers";
import DetailsReducer from "../components/HomeNew/Details/details-store/details-reducers";
import DesignReducer from "../components/HomeNew/Design/design-store/design-reducers";

const rootReducer = combineReducers({
  spinner: SpinnerReducer,
  toaster: ToastReducer,
  login: LoginReducer,
  projectList: ProjectListReducer,
  surveyDetails: DetailsReducer,
  designDetails: DesignReducer,

});

export default rootReducer;