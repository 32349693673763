import React, { useState, useEffect } from 'react';

function PageRefreshDialog() {
  const [displayDialog, setDisplayDialog] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // For older browsers
    //   setDisplayDialog(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleConfirm = () => {
    // Handle saving changes here
    setDisplayDialog(false);
  };

  const handleCancel = () => {
    setDisplayDialog(false);
  };

  useEffect(() => {
    if (displayDialog) {
      const confirmExit = window.confirm('Your unsaved changes will not be saved. Do you want to save them?');

      if (confirmExit) {
        handleConfirm();
      } else {
        handleCancel();
      }
    }
  }, [displayDialog]);

  return null; // No need to render any UI for this component
}

export default PageRefreshDialog;
