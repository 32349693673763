import { TEST, FETCH_SURVEY_DEFINITION } from "./design-store-constants";

const initialState = {
    test: "test123",
    surveyDefinitionData: {}
};

const DesignReducer = (state = initialState, action) => {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload.test
            };
        case FETCH_SURVEY_DEFINITION:
            return {
                ...state,
                surveyDefinitionData: action.payload.surveyDefinitionData
            };
        default:
            return state;
    }
};


export default DesignReducer;
