import React, { useState, useEffect } from 'react'
import Button from '../../shared-components/Button/custom-button';
import DisplayLogicQuestion from './DisplayLogicQuestion';

const DisplayLogic = (props) => {
    const { question, questionList, onChange, activeQuestion, currentPage, reset,
        saveDLForEachQuestion } = props;
    const filteredQuestions = questionList.flatMap((page) => page.elements);

    const targetIndex = filteredQuestions.findIndex(obj => obj === question);
    const updatedList = targetIndex !== -1 ? filteredQuestions.slice(0, targetIndex) : [];
    const newList = updatedList.filter((question) => {
        const questionType = question.type;

        // Exclude if both "matrix" and "matrixmultiChoice" types are present
        if (questionType === "matrix" && questionType === "matrixmultiChoice") {
            return false;
        }

        // Exclude if only the "matrix" type is present
        if (questionType === "matrix") {
            return false;
        }

        // Exclude if only the "matrixmultiChoice" type is present
        if (questionType === "matrixmultiChoice") {
            return false;
        }

        return true;
    });

    const operators = [
        { text: 'Equals', value: '=' },
        { text: 'Greater Than', value: '>' },
        { text: 'Less Than', value: '<' },
        { text: 'Greater Than or Equals', value: '>=' },
        { text: 'Less Than or Equals', value: '<=' },
        { text: 'Not Equals', value: '!=' },
        // { text: 'Any', value: 'any' },
        // { text: 'All', value: 'all' },
    ];

    console.log(newList, "New LIst in DL");

    const handleChange = (e, type, question) => {
        // console.log(e, type, question, "onChange sub parent");
        onChange(e, type, question);
    }
    return (
        question ?
            <div className="display-logic">
                {question.questionSequence !== 1
                    ? <div>
                        <DisplayLogicQuestion
                            newList={newList}
                            operators={operators}
                            onChange={handleChange}
                            question={question}
                            saveDLForEachQuestion={saveDLForEachQuestion}
                        />


                    </div>
                    : <div className="row" style={{ marginTop: 30 }}>
                        <div className="col-12">
                            <span className="tab-view-label"
                                style={{ 'fontWeight': 'bold', 'fontSize': '14px', 'marginBottom': '3px' }}>
                                Display Logic is not available for the first question.</span>
                        </div>
                    </div>}
            </div>
            : <div></div>
    )
}

export default DisplayLogic