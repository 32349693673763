import React, { useState, useCallback } from 'react'
import '../design.css'
import InfoIcon from '../../../shared-components/Info-Icon/InfoIcon';
import { Dropdown } from "primereact/dropdown";
import { ColorPicker } from 'primereact/colorpicker';

const SettingsContent = (props) => {
    const { dataContent, onChange } = props;
    const [bgColor, setBgColor] = useState(
        dataContent.surveyheadingBackground['background-color']
    );
    const [titleColor, setTitleColor] = useState(dataContent.surveyheadingForeground["color"]);

    function debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        }
    }

    const debouncedOnChange = useCallback(
        debounce(
            (event) =>
                onChange(event, 'surveyheadingBackgroundColor'),
            500
        ),
        []
    );

    const onChangeColor = (event) => {
        setBgColor(`#${event.value}`);
        debouncedOnChange(event);
    };

    const debouncedOnTitleChange = useCallback(
        debounce(
            (event) =>
                onChange(event, 'surveyheadingForeground'),
            500
        ),
        []
    );
    const onTitleChangeColor = (event) => {
        setTitleColor(`#${event.value}`);
        debouncedOnTitleChange(event);
    }
        ;

    const dropDownOptions = [
        { label: "Left", value: "left" },
        { label: "Right", value: "right" },
    ];

    const handleInputChange = (event) => {
        const value = event.target.value;
        // Check if the input value is a valid color code
        if (/^#[0-9A-F]{6}$/i.test(value)) {
            onChangeColor(value);
        }
        setBgColor(value);
    };

    const handleTitleChangeColor = (event) => {
        const value = event.target.value;
        // Check if the input value is a valid color code
        if (/^#[0-9A-F]{6}$/i.test(value)) {
            onTitleChangeColor(value);
        }
        setTitleColor(value);
    }
    return (
        <div className=''>
            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                    Logo Position
                </div>
                <div className="col-7 d-flex justify-content-start">
                    <Dropdown
                        value={dataContent.logoPosition}
                        options={dropDownOptions}
                        defaultValue="right"
                        placeholder="Select an option"
                        style={{ width: '50%' }}
                        onChange={(e) => onChange(e.value, "logoPosition", dataContent)}

                    />
                </div>
            </div>
            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                    Survey Heading Background Colour
                </div>
                <div className="col-7 d-flex justify-content-start">
                    <input type="text" value={`${bgColor}`} onChange={handleInputChange} />
                    <ColorPicker
                        value={bgColor}
                        onChange={onChangeColor}
                        style={{ width: '50%' }}
                    />
                </div>
            </div>
            <div className="row align-items-center mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="col-3 tab-view-label" style={{ fontWeight: "bold" }}>
                    Survey Heading Title Colour
                </div>
                <div className="col-7 d-flex justify-content-start">
                    <input type="text" value={`${titleColor}`} onChange={handleTitleChangeColor} />
                    <ColorPicker
                        value={titleColor}
                        onChange={onTitleChangeColor}
                        style={{ width: '50%' }}
                    />
                </div>
            </div>
        </div>
    )
}


export default SettingsContent