import React from 'react';
import { Spinner } from 'react-bootstrap';
import './FeatureNotImplemented.css';

const FeatureNotImplemented = ({msg}) => {
  return (
    <div className="feature-not-implemented">
      <Spinner animation="grow" variant="primary" />
      <h3>{msg} Feature is yet to be implemented</h3>
    </div>
  );
};

export default FeatureNotImplemented;
