import axios from "axios";
import { API_END_POINT } from "../../../../config";
import httpUtil from "../../../../utlities/http-util";
import { startSpinner, stopSpinner } from "../../../shared-components/custom-spinner/custom-spinner-store/spinner-actions";
import { TestAction, getSurveyDetailsByID } from "./details-actions-creators";
import { loadToaster } from "../../../shared-components/toaster/toaster-actions-creators";
import { fetchCurrentSurveyID, fetchCurrentSurveyName } from "../../ProjectList/home-store/home-actions-creators";

export const fetchTestCall = () => {
    return (dispatch) => {
        try {
            // dispatch(startSpinner());
            dispatch(TestAction("ravikrishnapunnam@gmail.com"));

        } catch (err) {

        }

    };
};
export const createOrUpdateSurveyDetails = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await axios.post(`${API_END_POINT.BASE_URL}/api/Survey/PostSurvey`, postObject);
            dispatch(loadToaster({ type: 'success', title: 'Saved Successfully!!', msg: '' }));
            dispatch(stopSpinner());
            return response.data.data.Status;

        } catch (err) {
            console.error(err);
            dispatch(stopSpinner());
            throw err; // Throw the error to the caller
        }
    };
};

export const fetchSurveyDetailsByID = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await axios.get(`${API_END_POINT.BASE_URL}/api/Survey/GetSurveyById/${postObject}`);
            let getDetails = response.data.data;
            dispatch(fetchCurrentSurveyID(getDetails.survey_id));
            dispatch(fetchCurrentSurveyName(getDetails.survey_name));
            getDetails.end_date = getDetails.end_date.split("T")[0];
            getDetails.start_date = getDetails.start_date.split("T")[0];
            dispatch(getSurveyDetailsByID(getDetails));
            dispatch(stopSpinner());

        } catch (err) {
            console.error(err);
            dispatch(stopSpinner());
            throw err; // Throw the error to the caller
        }
    };
};
